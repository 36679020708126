import { CountDown, Modal, Spiner } from 'components/shared';
import React from 'react';
import { useStore } from 'services/context';
import { checkIsEmail, checkIsNumbers, translate } from 'utils/helperFunctions';
type Props = {
  isLoading: boolean;
  isEmailVisible: boolean;
  setIsEmailVisible: (visible: boolean) => void;
  isEmailCodeVisible: boolean;
  setIsEmailCodeVisible: (visible: boolean) => void;
  emailDetails: { [key: string]: any };
  setEmailDetails: (e: any) => void;
  onChangeEmail: () => void;
  onValidateEmailCode: () => void;
  code: string;
  setCode: (e: string) => void;
};

export const ChangeEmail = (props: Props) => {
  const {
    isLoading,
    isEmailVisible,
    setIsEmailVisible,
    isEmailCodeVisible,
    setIsEmailCodeVisible,
    emailDetails,
    setEmailDetails,
    onChangeEmail,
    onValidateEmailCode,
    code,
    setCode,
  } = props;
  const [state, _] = useStore();
  const { userData } = state.authStore;

  const ChangeColorAfterStep = (text: string) =>
    text.split(' ').map((word, index) => {
      if (index === 1) {
        return (
          <span key={index} className='text-nell-blue'>
            {word + ' '}
          </span>
        );
      } else {
        return <span key={index}>{word + ' '}</span>;
      }
    });

  return (
    <Modal
      visible={isEmailVisible}
      onClose={() => {
        setIsEmailVisible(false);
        setIsEmailCodeVisible(false);

        setEmailDetails({
          email: '',
          confirmEmail: '',
        });
        setCode('');
      }}
      modalTitle={translate('settingsScreen.changeEmail')}
    >
      <div className='flex flex-col space-y-4 mb-6 px-4 md:px-8'>
        {!isEmailCodeVisible ? (
          <>
            <p className='text-white font-light text-[16px] mb-4'>
              {ChangeColorAfterStep(translate('settingsScreen.StepOneOfTwo'))}
            </p>
            <div>
              <div className='mt-1 flex flex-col gap-2'>
                <label htmlFor='newEmail' className='text-sm text-nell-blue'>
                  {translate('profileScreen.newEmailLabel')}
                </label>
                <input
                  placeholder={translate('profileScreen.newEmail')}
                  type={'email'}
                  id={'newEmail'}
                  value={emailDetails.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailDetails({ ...emailDetails, email: e.target.value })
                  }
                  className='tw-input text-primary-dark'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div>
              <div className='mt-1 flex flex-col gap-2'>
                <label htmlFor='confirmNewEmail' className='text-sm text-nell-blue'>
                  {translate('profileScreen.confirmNewEmailLabel')}
                </label>
                <input
                  placeholder={translate('profileScreen.confirmNewEmail')}
                  type={'email'}
                  id={'confirmNewEmail'}
                  value={emailDetails.confirmEmail}
                  onChange={e => setEmailDetails({ ...emailDetails, confirmEmail: e.target.value })}
                  className='tw-input text-primary-dark'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div className='self-center w-full'>
              <button
                type='button'
                disabled={
                  isLoading ||
                  !checkIsEmail(emailDetails.email) ||
                  !checkIsEmail(emailDetails.confirmEmail) ||
                  emailDetails.email !== emailDetails.confirmEmail ||
                  userData?.email === emailDetails.email
                }
                className='tw-button !w-full mt-5'
                onClick={onChangeEmail}
              >
                {isLoading && <Spiner className='ml-44' />}
                {translate('common.continue')}
              </button>
            </div>
          </>
        ) : (
          <>
            {' '}
            <p className='text-white self-start font-light text-[16px]  mb-4'>
              {ChangeColorAfterStep(translate('settingsScreen.StepTwoOfTwo'))}
            </p>
            <p className='mt-3 text-base font-normal text-primary-light w-full'>
              {translate('profileScreen.changeEmailCodeDescription')}
            </p>
            <div>
              <div className='mt-1 flex flex-col gap-2'>
                <label htmlFor='securityCode' className='text-sm text-nell-blue '>
                  {translate('profileScreen.verificationCode')}
                </label>
                <div className='relative'>
                  <input
                    placeholder={translate('profileScreen.enterCode')}
                    type='text'
                    id='securityCode'
                    autoComplete='off'
                    autoFocus
                    value={code}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkIsNumbers(e.target.value) && setCode(e.target.value)
                    }
                    className='tw-input !px-1.5 md:!px-4'
                    style={{ textAlign: 'left' }}
                  />

                  <CountDown duration={30} onResend={onChangeEmail} />
                </div>
              </div>
            </div>
            <div className='self-center !w-full'>
              <button
                type='button'
                disabled={isLoading || !code}
                className='tw-button mt-5 !w-full'
                onClick={onValidateEmailCode}
              >
                {isLoading && <Spiner className='ml-52 md:ml-44' />}
                {translate('settingsScreen.changeEmail')}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
