import axios, { InternalAxiosRequestConfig } from 'axios';
import { publicEndPoints, badTokenMessages } from './axiosConfiguration';
import { getStorageLoginData } from './StorageServices';
import { handleResponseError } from 'services/commonResponse';
import { AuthServices } from 'services/apis/Auth';
import { AuthSessionServices } from 'services/apis/AuthSession';
const { protocol } = window.location;

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const resolverAndRejecter = () => {
  return new Promise(function (resolve, reject) {
    failedQueue.push({ resolve, reject });
  });
};

axios.defaults.baseURL =
  protocol === 'https:' ? process.env.REACT_APP_URL_SAFE : process.env.REACT_APP_URL;
axios.defaults.timeout = 180000;

// Add a request interceptor
axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    const accessToken = getStorageLoginData('access_token');
    // Check if the end point is public (no need to header access_token)
    if (
      config.url &&
      config.headers &&
      publicEndPoints.find(endPoint => `${config.url}`.includes(endPoint))
    ) {
      delete config.headers?.access_token;
    } else {
      if (config.headers && accessToken) {
        config.headers.access_token = accessToken;
      }
    }
    //inject app key
    if (config.headers && !config.headers.key) {
      config.headers.key = process.env.REACT_APP_TENANT;
    }
    return config;
  },
  error => {
    throw error;
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const status = error?.response?.status;
    const originalRequest = error.response.config;
    const serviceName = error.response.config.url.split('/')[0];
    const isOauthService = serviceName === 'oauth';
    const shouldSkipRefresh = isOauthService;
    const isBadToken = badTokenMessages.includes(
      error?.response?.data?.errors?.details?.[0]?.message
    );
    const refreshStatus = status === 401 && isBadToken;
    const isAcl = error?.config?.url === '/soajs/acl' && error?.config?.headers?.access_token;
    const refresh = refreshStatus || isAcl;

    if (!shouldSkipRefresh && refresh && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          await resolverAndRejecter();
          const accessToken = getStorageLoginData('access_token');
          originalRequest.headers.access_token = accessToken;
          return await axios(originalRequest);
        } catch (err) {
          throw err;
        }
      }
      isRefreshing = true;
      originalRequest._retry = true;

      try {
        await AuthServices.refreshTokens();
        processQueue(null);
      } catch (err) {
        await AuthSessionServices.clearSession();
      } finally {
        isRefreshing = false;
      }
      return new Promise(function (resolve, reject) {
        resolve(axios(originalRequest));
        reject('Error in processing requests');
      });
    }
    throw error;
  }
);

axios.interceptors.response.use(
  response => {
    const contentType = response?.headers?.['content-type'];
    const isJson = contentType?.includes('application/json');
    const hasData = response?.hasOwnProperty('data');
    const isObject = typeof response?.data === 'object';
    const hasResult = response?.data?.hasOwnProperty('result');
    if (isJson && isObject && hasResult) {
      if (!response?.data?.result) {
        return handleResponseError();
      }
      return response.data.data;
    }
    if (hasData) {
      return response.data;
    }
    return response;
  },
  error => {
    const errorObject = error?.response?.data;
    const isFromApi = !!errorObject?.errors;
    if (!isFromApi) {
      return handleResponseError();
    }
    const url = error?.config?.url;
    const serviceName = url?.split('/')[0];
    return handleResponseError(errorObject, serviceName);
  }
);
