import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { TiTick } from 'react-icons/ti';

type Option = {
  value: string;
  label: string;
};
type Props = {
  options: Array<Option>;
  selectedValues: any[];
  placeholder?: string;
  onChange: (value: any) => void;
  className?: string;
  classNameInput?: string;
  editUser?: boolean;
};

export const CustomCombobox: React.FC<Props> = React.memo(
  ({ options, onChange, selectedValues, placeholder }) => {
    const [checkedValue, setCheckedValue] = useState<any>(selectedValues);
    const [open, setOpen] = useState<boolean>(false);

    const handleOnchange = (e: any) => {
      const { value } = e.target;
      if (!checkedValue.includes(value)) {
        setCheckedValue([...checkedValue, value]);
      } else {
        const removeUnchecked = checkedValue.filter((code: any) => code !== value);
        setCheckedValue(removeUnchecked);
      }
    };

    function useOutsideAlerter(ref: any) {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
      onChange?.(checkedValue);
    }, [checkedValue]);

    const getDisplayOptions = (displayOptions: Array<Option>) => {
      const displayStr = displayOptions.map(displayOption => displayOption).join(' - ');
      if (displayOptions.length > 3) {
        return `${displayOptions
          .map(displayOption => displayOption)
          .slice(0, 3)
          .join(' - ')} +${displayOptions.length - 3}...`;
      } else {
        return displayStr;
      }
    };

    return (
      <div ref={wrapperRef} className={`relative `}>
        <div
          className={`${open ? '!border-nell-blue' : '!border-nell-light-grey'}
            tw-input relative  !text-center  tracking-[3.5px] !text-sm !font-bold py-3 z-10 cursor-pointer`}
          onClick={() => setOpen(!open)}
        >
          {checkedValue?.length ? (
            <div className='truncate capitalize w-fit pr-5'>{getDisplayOptions(checkedValue)}</div>
          ) : (
            <div className='opacity-50 w-fit'>{placeholder}</div>
          )}
          <IoIosArrowDown
            className={`absolute right-5 top-0 text-xl translate-y-1/2 text-nell-light-grey ${
              open ? 'rotate-180' : ''
            }`}
          />
        </div>
        {open && (
          <div
            className={
              'absolute w-full flex flex-col bg-nell-black border border-nell-blue  rounded-[5px] mt-2 h-[102px]  overflow-y-auto py-[10px]'
            }
          >
            {options?.map(option => {
              return (
                <div
                  key={option.value}
                  className='flex items-center  py-2 px-4 hover:bg-primary-leaf/20 '
                >
                  <label
                    htmlFor={option.value}
                    className={`flex justify-center items-center w-6 h-6 cursor-pointer bg-primary-leaf rounded text-primary-mint  ${
                      checkedValue.includes(option.value)
                        ? 'bg-nell-blue'
                        : 'border border-nell-blue'
                    }`}
                  >
                    {checkedValue.includes(option.value) && (
                      <div>
                        <TiTick className='text-nell-white rounded bg-nell-blue' />
                      </div>
                    )}
                    <input
                      type='checkbox'
                      id={option.value}
                      value={option.value}
                      className='hidden'
                      onChange={handleOnchange}
                    />
                  </label>

                  <label htmlFor={option.value} className='cursor-pointer'>
                    <div
                      className={`pl-2 capitalize ${
                        checkedValue.includes(option.value) ? 'font-extrabold' : 'font-bold'
                      } `}
                    >
                      {option.label}
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
