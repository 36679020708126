import { ReAuthData, User } from 'services/apis/Auth';

export const setUserKeyACL = (acl: any, index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (!acl_cached) {
    acl_cached = {};
  } else {
    acl_cached = JSON.parse(acl_cached);
  }
  acl_cached[index] = acl;
  localStorage.setItem('userACL', JSON.stringify(acl_cached));
};

export const getUserKeyACL = (index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (acl_cached) {
    acl_cached = JSON.parse(acl_cached);
    if (acl_cached[index]) {
      return acl_cached[index];
    }
  }
  return null;
};

export const clearUserKeyACL = () => {
  localStorage.removeItem('userACL');
};

export const getStorageLoginData = (key?: string | null) => {
  try {
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    if (key) {
      return loginData[key] || undefined;
    }
    return loginData;
  } catch (error) {
    return null;
  }
};

export const setStorageLoginData = (data: ReAuthData | null) => {
  localStorage.setItem('loginData', JSON.stringify({ ...data }));
};

export const getStorageUserData = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData;
  } catch (error) {
    return null;
  }
};

export const getUserDataByKey = (key: string) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData[key] || undefined;
  } catch (error) {
    return null;
  }
};

export const setStorageUserData = (user: User | null) => {
  localStorage.setItem('userData', JSON.stringify(user));
};
