import { Select } from 'components/shared';

type Props = {
  onChange: (keys: any) => void;
  filter?: any;
  clasName?: string;
  setdevEUISort?: any;
  setSerialNumberSort?: any;
};

const FactoryTagsFilter = (props: Props) => {
  const { onChange, filter, setdevEUISort, setSerialNumberSort, clasName } = props;

  const onChangeFilter = (newFilters = {}) => {
    onChange(newFilters);
  };
  return (
    <div className={`${clasName} px-4 pb-4 flex gap-4 flex-wrap`}>
      <div className='min-w-[120px]'>
        <Select
          options={[
            { value: 'asc', label: 'Asc' },
            { value: 'desc', label: 'Desc' },
          ]}
          onChange={e => {
            setSerialNumberSort(undefined);
            if (e?.value === undefined || e === undefined) {
              setdevEUISort(undefined);
              delete filter['sortBy[0][field]'];
              delete filter['sortBy[0][type]'];
              onChangeFilter({ skip: 0 });
            } else {
              setdevEUISort(e?.value);
              onChangeFilter({
                skip: 0,
                'sortBy[0][field]': 'devEUI',
                'sortBy[0][type]': e?.value,
              });
            }
          }}
          value={filter['sortBy[0][field]'] === 'devEUI' ? filter['sortBy[0][type]'] : undefined}
          toClearFilter={true}
          placeholder='DevEUI'
        />
      </div>
      <div className='min-w-[120px]'>
        <Select
          options={[
            { value: 'asc', label: 'Asc' },
            { value: 'desc', label: 'Desc' },
          ]}
          onChange={e => {
            setdevEUISort(undefined);
            if (e?.value === undefined || e === undefined) {
              setSerialNumberSort(undefined);
              delete filter['sortBy[0][field]'];
              delete filter['sortBy[0][type]'];
              onChangeFilter({ skip: 0 });
            } else {
              setSerialNumberSort(e?.value);
              onChangeFilter({
                skip: 0,
                'sortBy[0][field]': 'serialnumber',
                'sortBy[0][type]': e?.value,
              });
            }
          }}
          value={
            filter['sortBy[0][field]'] === 'serialnumber' ? filter['sortBy[0][type]'] : undefined
          }
          toClearFilter={true}
          placeholder='Serial Number'
        />
      </div>

      <div className='min-w-[120px]'>
        <Select
          options={[
            { value: 'all', label: 'All' },
            { value: 'new', label: 'New' },
            { value: 'pending', label: 'Pending' },
            { value: 'active', label: 'Active' },
            { value: 'deleted', label: 'Deleted' },
          ]}
          onChange={e => {
            onChangeFilter({ skip: 0, status: e?.value === 'all' ? undefined : e?.value });
          }}
          value={filter.status}
          placeholder='Status'
        />
      </div>
    </div>
  );
};

export default FactoryTagsFilter;
