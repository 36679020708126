import { Modal, Spiner } from 'components/shared';
import { useParams } from 'react-router-dom';
import { OrganizationServices } from 'services/apis/Organization';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from 'utils/helperFunctions';

export const OrganizationChangeStatus = ({
  companyProfile,
  setCompanyProfile,
  isStatusVisible,
  setIsStatusVisible,
}: any) => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const ChangeStatus = async () => {
    try {
      setLoading(true);
      await OrganizationServices.ChangeOrganizationStatus(`${id}`, {
        ...companyProfile,
        status: `${companyProfile?.status === 'active' ? 'inactive' : 'active'}`,
      });
      const company = await OrganizationServices.fetchCompanyById(`${id}`);
      setCompanyProfile(company);
      setLoading(false);
      setIsStatusVisible(false);
      toast.success(translate('organizationsScreen.statusUpdatedSuccessfully'));
    } catch (error: any) {
      setLoading(false);
      setLoading(false);
      setIsStatusVisible(false);
      toast.error(error?.message);
      throw error;
    }
  };
  return (
    <Modal
      visible={isStatusVisible}
      onClose={() => {
        setIsStatusVisible(false);
      }}
      modalTitle={translate('organizationsScreen.changeStatus')}
    >
      <div className={`w-full flex-col gap-2 justify-center px-4 md:px-8`}>
        <div>
          {translate('organizationsScreen.areYouSureYouWantToChangeTheStatusTo')}{' '}
          {companyProfile?.status === 'active' ? (
            <p className='text-nell-red font-heavyGaret'>{translate('common.inactive')}?</p>
          ) : (
            <p className='text-nell-green font-heavyGaret'>{translate('common.active')}?</p>
          )}
        </div>
        <div className='w-full flex gap-4 justify-end'>
          <button
            type='button'
            className='tw-button mt-5 !w-[130px] !h-[38px] md:!w-[150px] md:!h-12'
            onClick={() => {
              setIsStatusVisible(false);
            }}
          >
            {translate('common.cancel')}
          </button>
          <button
            type='button'
            className='tw-button mt-5 !w-[130px] !h-[38px] md:!w-[150px] md:!h-12'
            onClick={ChangeStatus}
          >
            {isLoading && <Spiner className='sm:ml-32' />}
            {translate('common.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
