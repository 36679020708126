import { CollectionIcon } from '@heroicons/react/outline';
import React from 'react';
export type AlignTypes = 'right' | 'left' | 'center' | string;
export type Column = {
  dataIndex: React.Key;
  render?: (p1: any, p2?: any) => any;
  align?: AlignTypes;
  wrap?: string;
  title: React.Key | React.ReactNode;
  className?: string;
  key?: React.Key;
  theadClass?: string;
};

type TableProps = {
  loading?: boolean;
  columns: Array<Column>;
  hover?: boolean;
  onClickRow?: (index: number, item: any) => void;
  dataSource: { [key: React.Key]: any }[];
  scroll?: boolean;
};

export const Table: React.FC<TableProps> = React.memo(
  ({ hover = false, columns, dataSource, onClickRow, scroll }) => {
    const getAlignText = (align?: AlignTypes): string => {
      try {
        let textAlign = '';
        switch (align) {
          case 'right':
            textAlign = 'text-right';
            break;
          case 'left':
            textAlign = 'text-left';
            break;
          case 'center':
            textAlign = 'text-center';
            break;
        }
        return textAlign;
      } catch (error) {
        return '';
      }
    };

    return (
      <div className='xpx-4 xsm:px-4 xlg:px-4 text-nell-white '>
        <div className='flex flex-col'>
          <div className='x-my-2'>
            <div className='xpy-2 align-middle inline-block min-w-full xsm:px-4 xlg:px-4'>
              <div
                className={`overflow-hidden min-h-[240px] ${
                  scroll ? 'md:overflow-y-auto md:h-[165px]' : ''
                }`}
              >
                <table className='min-w-full divide-y divide-white divide-opacity-25'>
                  <thead>
                    <tr>
                      {columns.map(({ title, align, theadClass }, index) => (
                        <th
                          scope='col'
                          key={index}
                          className={`${index === 0 ? 'pr-2 lg:pr-6 pl-4' : 'px-2 lg:px-6'}  ${
                            getAlignText(align) ? ` ${getAlignText(align)} ` : 'text-left'
                          } ${theadClass ? theadClass : ''} py-3 text-base font-black`}
                        >
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-white divide-opacity-25'>
                    {!dataSource?.length ? (
                      <tr className='text-nell-white h-52 relative'>
                        <td
                          colSpan={100}
                          className='flex gap-2 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-lg'
                        >
                          <CollectionIcon className='w-6 h-6 text-primary-gray' /> No Data
                        </td>
                      </tr>
                    ) : (
                      dataSource.map((item, index) => (
                        <tr
                          key={index}
                          className={`h-[70px] ${
                            hover
                              ? ' hover:bg-nell-black hover:text-white hover:font-extrabold'
                              : ''
                          } ${onClickRow ? 'cursor-pointer' : ''}`}
                          onClick={() => {
                            if (onClickRow) {
                              onClickRow(index, item);
                            }
                          }}
                        >
                          {columns.map(({ dataIndex, render, align }, colIndex) => {
                            return (
                              <td
                                key={`${index}-${colIndex}`}
                                className={`
                                ${colIndex === 0 ? 'pr-2 lg:pr-6 pl-4' : 'px-2 lg:px-6'} 
                                  py-4 whitespace-nowrap truncate max-w-0   
                                  text-sm
                                  ${getAlignText(align)}
                                  `}
                              >
                                {render ? render(item[dataIndex], item) : item[dataIndex]}
                              </td>
                            );
                          })}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
