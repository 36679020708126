import { Tabs } from 'components/shared';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';
import { Roles } from './Roles';
import { Users } from './Users';
import { getPathTitle } from 'screens/Dashboard';

export const Settings = () => {
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'users',
  });
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const tabParam = searchParams.get('tab');

  useEffect(() => {
    if (!tabParam || tabParam === 'users') {
      setActiveKeyTab('1');
    } else {
      setActiveKeyTab('2');
    }
  }, [tabParam]);

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <Users />;
      case '2':
        return <Roles />;
      default:
        return null;
    }
  };

  return (
    <div className={`relative sm:bg-nell-dark-grey sm:pt-20 rounded-[20px] mb-4`}>
      <Tabs
        activeKey={activeKeyTab}
        onChange={key => {
          setActiveKeyTab(key);
          setSearchParams({ tab: key === '1' ? 'users' : 'groups' });
        }}
        tabPanes={[
          { name: translate('settingsScreen.users'), key: '1' },
          { name: translate('settingsScreen.roles'), key: '2' },
        ]}
      />
      <div
        className={`sm:hidden flex items-center gap-5 flex-shrink-0 text-nell-white text-2xl pl-4 mt-4 reset-padding capitalize`}
      >
        {getPathTitle(pathname)}
      </div>
      {_renderTabs()}
    </div>
  );
};
