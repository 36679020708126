import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICompanyProfile, OrganizationServices } from 'services/apis/Organization';
import { CustomRadio, CustomSelect, Spiner } from 'components/shared';
import { toast } from 'react-toastify';
import { checkIsEmail, translate } from 'utils/helperFunctions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import countriesRegions from 'country-region-data/data.json';

export const CreateOrganization = () => {
  const { id } = useParams();
  const [companyProfile, setCompanyProfile] = useState<ICompanyProfile>({
    name: '',
    description: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'United States',
    },
    profile: {
      info: {
        operatingName: '',
        website: '',
      },
      contactInfo: {
        salutation: 'Mr.',
        firstName: '',
        lastName: '',
        title: '',
        phone: '',
        email: '',
        preferredContact: 'phone',
        preferredLanguage: 'english',
      },
    },
  });
  const [phoneCode, setPhoneCode] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidWebsite, setIsValidWebsite] = useState<boolean>(true);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const fetchPharmacyDetails = async () => {
    if (id && id !== 'new') {
      try {
        const details = await OrganizationServices.fetchCompanyById(id);
        setCompanyProfile({
          ...companyProfile,
          ...details,
          profile: {
            ...details.profile,
            info: {
              ...details.profile.info,
              phone: details.profile.info?.phone?.slice(1),
            },
            contactInfo: {
              ...details.profile.contactInfo,
              phone: details?.profile?.contactInfo?.phone?.slice(1),
            },
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  useEffect(() => {
    fetchPharmacyDetails();
  }, []);

  const onAddCompany = async () => {
    setIsLoading(true);
    try {
      await OrganizationServices.addCompany('company', {
        ...companyProfile,
        address: {
          ...companyProfile.address,
          postalCode: companyProfile?.address?.postalCode.replace(' ', '') || '',
        },
        profile: {
          ...companyProfile.profile,
          contactInfo: {
            ...companyProfile.profile.contactInfo,
            phone: `${phoneCode}${companyProfile?.profile?.contactInfo?.phone || ''}`,
          },
        },
      });
      toast.success(translate('organizationsScreen.companyAddedSuccessfully'));
      history.back();
      setIsLoading(false);
      return;
    } catch (error: any) {
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  const onUpdateCompanyProfile = async () => {
    if (id) {
      try {
        setIsLoading(true);
        await OrganizationServices.updateCompanyProfile(id, {
          ...companyProfile,
          address: {
            ...companyProfile.address,
            postalCode: companyProfile?.address?.postalCode.replace(' ', '') || '',
          },
          profile: {
            ...companyProfile.profile,
            contactInfo: {
              ...companyProfile.profile.contactInfo,
              phone: `${phoneCode}${companyProfile?.profile?.contactInfo?.phone || ''}`,
            },
          },
        });
        toast.success(translate('organizationsScreen.companyUpdatedSuccessfully'));
        history.back();
        setIsLoading(false);
        return;
      } catch (error: any) {
        toast.error(error?.message);
        setIsLoading(false);
      }
    }
  };

  const isFormValid = () => {
    return (
      !!companyProfile.name &&
      !!companyProfile.description &&
      !!companyProfile.address.address1 &&
      !!companyProfile.address.city &&
      !!companyProfile.address.region &&
      !!companyProfile.address.country &&
      !!companyProfile.profile.contactInfo.salutation &&
      !!companyProfile.profile.contactInfo.firstName &&
      !!companyProfile.profile.contactInfo.lastName &&
      !!companyProfile.profile.contactInfo.title &&
      !!companyProfile.profile.contactInfo.phone &&
      !!companyProfile.profile.contactInfo.email &&
      !!companyProfile.profile.contactInfo.preferredContact &&
      !!companyProfile.profile.contactInfo.preferredLanguage &&
      (!!companyProfile?.profile?.info?.website ? isValidWebsite : true) &&
      checkIsEmail(companyProfile.profile.contactInfo.email)
    );
  };

  const _renderInfo = () => {
    return (
      <div className=''>
        <div className='text-nell-white tracking-[2.2px] font-black text-sm uppercase'>
          {translate('organizationsScreen.companyInfo')}
        </div>
        <div className='mt-6'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
            <div className='flex flex-col gap-3'>
              <label htmlFor='legalName' className='text-sm font-bold'>
                {translate('organizationsScreen.legalName')}*
              </label>
              <input
                type='text'
                name='legalName'
                id='legalName'
                autoComplete='off'
                value={companyProfile?.name || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyProfile({
                    ...companyProfile,
                    name: e.target.value,
                  })
                }
                className='tw-input'
                style={{ textAlign: 'left' }}
              />
            </div>

            <div className='flex flex-col gap-3'>
              <label htmlFor='operatingName' className='text-sm font-bold'>
                {translate('organizationsScreen.operatingName')}
              </label>
              <input
                type='text'
                name='operatingName'
                id='operatingName'
                autoComplete='off'
                value={companyProfile?.profile?.info?.operatingName || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyProfile({
                    ...companyProfile,
                    profile: {
                      ...companyProfile?.profile,
                      info: {
                        ...companyProfile?.profile?.info,
                        operatingName: e.target.value,
                      },
                    },
                  })
                }
                className='tw-input'
                style={{ textAlign: 'left' }}
              />
            </div>

            <div className='relative flex flex-col gap-3'>
              <label htmlFor='website' className='text-sm font-bold'>
                {translate('organizationsScreen.website')}
              </label>
              <input
                type='url'
                name='website'
                id='website'
                autoComplete='off'
                value={companyProfile?.profile?.info?.website || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                  if (regex.test(e.target.value) || !e.target.value) {
                    setIsValidWebsite(true);
                  } else {
                    setIsValidWebsite(false);
                  }
                  setCompanyProfile({
                    ...companyProfile,
                    profile: {
                      ...companyProfile.profile,
                      info: {
                        ...companyProfile?.profile?.info,
                        website: e.target.value,
                      },
                    },
                  });
                }}
                className={`tw-input ${isValidWebsite ? '' : '!border-2 !border-primary-coral '}`}
                style={{ textAlign: 'left' }}
              />
              {!isValidWebsite && (
                <p className='absolute text-xs text-nell-red left-4 -bottom-5'>
                  {translate('organizationsScreen.addValidWebsite')}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-3 lg:col-span-2'>
              <label htmlFor='bio' className='text-sm font-bold'>
                {translate('common.description')}*
              </label>
              <textarea
                name='bio'
                id='bio'
                autoComplete='off'
                value={companyProfile?.description || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setCompanyProfile({
                    ...companyProfile,
                    description: e.target.value,
                  })
                }
                className='tw-input min-h-[130px]'
                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderAddress = () => {
    return (
      <div className=''>
        <div className='text-primary-leaf/50 tracking-[2.2px] font-black text-sm uppercase mb-4'>
          {translate('common.address')}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
          <div className='flex flex-col gap-3'>
            <label htmlFor='address1' className='text-sm font-bold'>
              {translate('profileScreen.address1')}*
            </label>
            <input
              type='text'
              name='address1'
              id='address1'
              autoComplete='off'
              value={companyProfile?.address?.address1 || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    address1: e.target.value,
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='address2' className='text-sm font-bold'>
              {translate('profileScreen.address2')}
            </label>
            <input
              type='text'
              name='address2'
              id='address2'
              autoComplete='off'
              value={companyProfile?.address?.address2 || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    address2: e.target.value,
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='city' className='text-sm font-bold'>
              {translate('profileScreen.city')}*
            </label>
            <input
              type='text'
              name='city'
              id='city'
              autoComplete='off'
              value={companyProfile?.address?.city || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, city: e.target.value },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>

          <div className='flex flex-col gap-3'>
            <label htmlFor='zip' className='text-sm font-bold'>
              {translate('profileScreen.postalCode')}
            </label>
            <input
              type='text'
              name='zip'
              id='zip'
              autoComplete='off'
              value={companyProfile?.address?.postalCode || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, postalCode: e.target.value },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>{translate('profileScreen.country')}*</label>
            <CustomSelect
              options={countriesRegions.map(country => ({
                label: country.countryName,
                value: country.countryName,
              }))}
              value={companyProfile?.address?.country || 'United States'}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    country: e?.value as string,
                    region: '',
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {companyProfile?.address?.country === 'United States'
                ? `${translate('profileScreen.state')}*`
                : companyProfile?.address?.country === 'Canada'
                ? `${translate('profileScreen.province')}*`
                : `${translate('profileScreen.region')}*`}
            </label>
            <CustomSelect
              options={
                countriesRegions
                  .find(c => c.countryName === companyProfile?.address?.country)
                  ?.regions.map(region => ({ label: region.name, value: region.name } || [])) || []
              }
              value={companyProfile?.address?.region || ''}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, region: e?.value as string },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderContactInfo = () => {
    return (
      <div className=''>
        <div className='text-primary-leaf/50 tracking-[2.2px] font-black text-sm uppercase mb-4'>
          {translate('organizationsScreen.contactPerson')}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {translate('organizationsScreen.salutations')}*
            </label>
            <CustomSelect
              options={[
                { label: 'Mr.', value: 'Mr.' },
                { label: 'Mrs.', value: 'Mrs.' },
                { label: 'Ms.', value: 'Ms.' },
                { label: 'Mx.', value: 'Mx.' },
                { label: 'Dr.', value: 'Dr.' },
                { label: 'Dre.', value: 'Dre.' },
              ]}
              value={companyProfile?.profile?.contactInfo?.salutation || 'Mr.'}
              onChange={(e: any) => {
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      salutation: e.value,
                    },
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='firstName' className='text-sm font-bold'>
              {translate('profileScreen.firstName')}*
            </label>
            <input
              type='text'
              name='firstName'
              id='firstName'
              autoComplete='off'
              value={companyProfile?.profile?.contactInfo?.firstName || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      firstName: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='lastName' className='text-sm font-bold'>
              {translate('profileScreen.lastName')}*
            </label>
            <input
              type='text'
              name='lastName'
              id='lastName'
              autoComplete='off'
              value={companyProfile?.profile?.contactInfo?.lastName || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      lastName: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='title' className='text-sm font-bold'>
              {translate('organizationsScreen.title')}*
            </label>
            <input
              type='text'
              name='title'
              id='title'
              autoComplete='off'
              value={companyProfile?.profile?.contactInfo?.title || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      title: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='relative flex flex-col gap-3'>
            <label className='text-sm font-bold' htmlFor='phone'>
              {translate('profileScreen.phoneNumber')}*
            </label>
            <div className='flex gap-2'>
              <span className='absolute top-[36px] sm:top-[38px] left-2 md:left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{
                    background: '#171717',
                    border: 'none',
                    bottom: '2px',
                    top: '2px',
                    left: '2px',
                  }}
                  containerStyle={{
                    width: `${innerWidth < 640 ? '88px' : '88px'}`,
                    margin: 'auto',
                  }}
                  dropdownStyle={{ color: 'white', background: '#161919' }}
                  value={phoneCode}
                  onChange={(phone: string) => setPhoneCode(phone)}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 bg-transparent focus:ring-0 sm:text-sm text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
              <input
                type='text'
                name='phone'
                id='phone'
                value={companyProfile?.profile?.contactInfo?.phone || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value)
                    setCompanyProfile({
                      ...companyProfile,
                      profile: {
                        ...companyProfile.profile,
                        contactInfo: {
                          ...companyProfile?.profile?.contactInfo,
                          phone: e.target.value,
                        },
                      },
                    });
                }}
                autoComplete='off'
                className={`tw-input !pl-24 md:!pl-28`}
                style={{ textAlign: 'left' }}
                placeholder={translate('profileScreen.phoneNumber')}
              />
            </div>
          </div>
          <div className='relative flex flex-col gap-3'>
            <label htmlFor='email2' className='text-sm font-bold'>
              {translate('common.email')}*
            </label>
            <input
              type='email'
              name='email2'
              id='email2'
              autoComplete='off'
              value={companyProfile?.profile?.contactInfo?.email || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (checkIsEmail(e.target.value)) {
                  setIsValidEmail(true);
                } else {
                  setIsValidEmail(false);
                }
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      email: e.target.value,
                    },
                  },
                });
              }}
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
            {!isValidEmail && (
              <p className='absolute text-xs text-nell-red left-4 -bottom-5'>
                {translate('organizationsScreen.addValidEmail')}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {translate('organizationsScreen.preferredContact')}*
            </label>
            <CustomRadio
              options={[
                { value: 'phone', label: 'Phone Number' },
                { value: 'email', label: 'Email' },
              ]}
              value={companyProfile?.profile?.contactInfo?.preferredContact || 'phone'}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      preferredContact: e?.value,
                    },
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {translate('organizationsScreen.preferredLanguage')}*
            </label>
            <CustomRadio
              options={[
                { value: 'english', label: 'English' },
                { value: 'french', label: 'French' },
              ]}
              value={companyProfile?.profile?.contactInfo?.preferredLanguage || 'english'}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      preferredLanguage: e?.value,
                    },
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderAcceptbutton = () => {
    return (
      <div className='flex justify-end m-5'>
        <button
          type='button'
          className='tw-button w-32'
          disabled={!isFormValid()}
          onClick={() => (id === 'new' ? onAddCompany() : onUpdateCompanyProfile())}
        >
          {isLoading && <Spiner className='ml-24' />}
          {translate('common.save')}
        </button>
      </div>
    );
  };

  const _renderCreateCompany = () => {
    return (
      <div className='flex flex-col gap-5 divide-y-2 divide-nell-mid-grey'>
        <div className='p-5'>{_renderInfo()}</div>
        <div className='p-5'>{_renderAddress()}</div>
        <div className='p-5'>{_renderContactInfo()}</div>
        <div>{_renderAcceptbutton()}</div>
      </div>
    );
  };

  return (
    <div className='relative sm:bg-nell-dark-grey text-nell-white sm:pt-2 mb-10 rounded-[20px] overflow-hidden'>
      {_renderCreateCompany()}
    </div>
  );
};
