import React, { useState, useRef, useEffect } from 'react';

type Props = {
  description: string;
  title?: string;
};

const DetailsLessMore = ({ description, title }: Props) => {
  const contentRefs: any = useRef(null);
  const [expanded, setExpanded] = useState({
    detailNeedexpand: false,
    isDetailExpanded: false,
  });
  useEffect(() => {
    const ref = contentRefs.current;
    if (ref) {
      const divHeight = ref.clientHeight;
      const lineHeight = parseInt(window.getComputedStyle(ref).lineHeight);
      const numberOfLines = Math.ceil(divHeight / lineHeight);
      setExpanded({
        ...expanded,
        detailNeedexpand: numberOfLines > 4,
      });
    }
  }, [description]);

  return (
    <div className='relative pb-5'>
      <div
        ref={contentRefs}
        className={`font-normal ${
          expanded.isDetailExpanded || !expanded.detailNeedexpand ? '' : 'ellipsis'
        }`}
      >
        {title ? <span>{title}: </span> : null}{' '}
        <span className={`font-normal text-primary-gray`}>{description || '-'}</span>
        {expanded.detailNeedexpand && (
          <button
            className='absolute right-2 bottom-0 z-10 text-sm leading-6 text-white bg-primary-dark-grey'
            onClick={() => {
              setExpanded({
                ...expanded,
                isDetailExpanded: !expanded.isDetailExpanded,
              });
            }}
          >
            {expanded.isDetailExpanded ? 'See Less' : 'See More'}
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailsLessMore;
