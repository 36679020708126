import store from 'services/context/store';
import { AuthServices } from '../Auth';
import { setIsLoggedIn, setLoginData, updateUserAccount } from 'services/context/Auth/actions';
import { getStorageLoginData } from 'utils/StorageServices';
import { UracServices } from '../Urac';

export const AuthSessionServices = {
  createSession: async () => {
    try {
      const loginData = getStorageLoginData();
      if (!loginData?.access_token || !loginData?.refresh_token) {
        throw new Error('No login data found');
      }
      const user = await UracServices.fetchUser();
      if (!user) {
        // in case API returns null but not an error
        throw new Error('User not found');
      }
      store.dispatch(setIsLoggedIn(true));
    } catch (error) {
      console.log('error', error);
      AuthSessionServices.clearSession();
    }
  },
  clearSession: async () => {
    try {
      await AuthServices.logout();
    } catch (error) {
      console.log('error', error);
    }

    try {
      localStorage.clear();
    } catch (error) {
      console.log('error', error);
    }

    store.dispatch(updateUserAccount(null));
    store.dispatch(setIsLoggedIn(false));
    store.dispatch(setLoginData(null));
  },
};
