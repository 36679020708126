import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Spiner } from 'components/shared/Spiner';
import { ChangePasswordProperties, UserAccountProperties } from 'services/apis/Auth';
import { useStore } from 'services/context';
import { updateUserAccount } from 'services/context/Auth/actions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../components/componentStyle/Styles.css';
import { translate } from 'utils/helperFunctions';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';
import { UracServices } from 'services/apis/Urac';
import { setStorageUserData } from 'utils/StorageServices';

const defaultEmailChange = {
  email: '',
  confirmEmail: '',
};
export const MyProfile = () => {
  const [state, dispatch] = useStore();
  const { userData } = state.authStore;
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [user, setUser] = useState<UserAccountProperties>({});
  const [isEmailVisible, setIsEmailVisible] = useState<boolean>(false);

  const [isEmailCodeVisible, setIsEmailCodeVisible] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [emailDetails, setEmailDetails] = useState(defaultEmailChange);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditableMode, setIsEditableMode] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<string>(userData?.profile?.countryCode || '1');
  const [phoneNumber, setPhoneNumber] = useState<string>(userData?.profile?.phoneNumber || '');

  const [userDetails, setUserDetails] = useState({
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    phone: userData?.phone || '',
  });
  const [newPassword, setNewPassword] = useState<ChangePasswordProperties>({
    oldPassword: '',
    password: '',
    confirmation: '',
  });

  useEffect(() => {
    UracServices.fetchUser()
      .then(data => {
        setUser(data);
        return;
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (userData) {
      setUserDetails({
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        phone: userData.phone || '',
      });
    }
  }, [userData, user]);

  const onSaveChangePassword = () => {
    setIsLoading(true);
    UracServices.changePassword(newPassword)
      .then(() => {
        setIsLoading(false);
        setIsPasswordVisible(false);
        setNewPassword({ oldPassword: '', password: '', confirmation: '' });
        toast.success(translate('profileScreen.passwordHasBeenChangedSuccessfully'));
        return;
      })
      .catch(error => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };

  const onSaveAccountSettings = () => {
    try {
      setIsEditableMode(mode => !mode);
      if (
        userData.firstName !== userDetails.firstName ||
        userData.lastName !== userDetails.lastName ||
        (phoneNumber !== '' && phoneNumber !== userData.profile.phoneNumber) ||
        phoneCode !== userData.profile.countryCode
      ) {
        if (isEditableMode) {
          setIsLoading(true);
          const profile = userData.profile || {};
          const payload = {
            ...userDetails,
            phone: `${phoneCode}${phoneNumber}`,
            profile: { ...profile, phoneNumber: phoneNumber, countryCode: phoneCode },
          };
          UracServices.updateAccount(payload)
            .then(userAccount => {
              if (userAccount) {
                toast.success(translate('profileScreen.accountHasBeenUpdatedSuccessfully'));
              }
              setIsLoading(false);
              setIsEditableMode(false);
              return;
            })
            .catch(error => {
              toast.error(error?.message);
              setIsLoading(false);
            });
        }
      }
    } catch (error) {}
  };

  const onChangeEmail = () => {
    try {
      if (emailDetails.email && emailDetails.email !== emailDetails.confirmEmail) {
        toast.error('The confirmation email does not match');
      } else {
        setIsLoading(true);
        UracServices.changeEmail(emailDetails.email)
          .then(() => {
            toast.success(translate('resetPasswordScreen.resendCodeSuccess'));
            setIsEmailCodeVisible(true);
            setIsLoading(false);
            return;
          })
          .catch(error => {
            setIsLoading(false);
            console.log({ error });
            toast.error(error?.message);
          });
      }
    } catch (error) {}
  };

  const onValidateEmailCode = () => {
    try {
      setIsLoading(true);
      UracServices.validateChangeEmailCode(code)
        .then(() => {
          const newUserData = { ...userData, email: emailDetails.email?.toLowerCase?.() };
          setStorageUserData(newUserData);
          dispatch(updateUserAccount(newUserData));
          toast.success(translate('profileScreen.changeEmailSuccess'));
          setIsLoading(false);
          setIsEmailVisible(false);
          setIsEmailCodeVisible(false);
          setCode('');
          setEmailDetails(defaultEmailChange);
          return;
        })
        .catch(error => {
          setIsLoading(false);
          toast.error(error?.message);
        });
    } catch (error) {}
  };

  const _renderAccountTab = () => {
    return (
      <>
        <div className='w-full flex flex-col gap-y-5 lg:gap-y-10 mb-5'>
          <div className='text-white rounded '>
            <div className=''>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-5 px-5 bg-nell-dark-grey pt-8 pb-7  md:m-0  rounded-[20px]'>
                <div className='w-full flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <label
                      htmlFor='_email'
                      className='text-nell-blue
                  font-light capitalize text-sm'
                    >
                      {translate('common.email')}
                    </label>
                    <span
                      onClick={() => setIsEmailVisible(true)}
                      className='
                  font-light capitalize text-sm cursor-pointer '
                    >
                      {translate('settingsScreen.changeEmail')}
                    </span>
                  </div>
                  <div className='mt-1 relative'>
                    <input
                      type='email'
                      name='email'
                      id='_email'
                      disabled
                      value={userData?.email}
                      className='tw-input'
                      style={{ textAlign: 'left' }}
                      placeholder={translate('common.email')}
                    />
                  </div>
                </div>
                <div className='w-full flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <label
                      htmlFor='_password'
                      className='text-nell-blue font-light capitalize text-sm'
                    >
                      {translate('common.password')}
                    </label>
                    <span
                      onClick={() => setIsPasswordVisible(true)}
                      className='
                  font-light capitalize text-sm cursor-pointer '
                    >
                      {translate('profileScreen.changePassword')}
                    </span>
                  </div>
                  <div className='mt-1 relative'>
                    <input
                      type='password'
                      name='password'
                      id='_password'
                      defaultValue={'password'}
                      disabled
                      className='tw-input'
                      style={{ textAlign: 'left' }}
                      placeholder={translate('common.password')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-white px-5 pt-8 pb-7 rounded-[20px] bg-nell-dark-grey md:mx-0'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5 w-full'>
              <div className='w-full flex flex-col gap-2'>
                <div className='flex justify-between items-center '>
                  <label htmlFor='fName' className='text-nell-blue font-lightcapitalize text-sm'>
                    {translate('profileScreen.firstName')}
                  </label>
                </div>
                <div className='mt-1 '>
                  <input
                    type='text'
                    id='fName'
                    value={userDetails.firstName}
                    disabled={!isEditableMode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUserDetails({ ...userDetails, firstName: e.target.value })
                    }
                    className='tw-input'
                    style={{ textAlign: 'left' }}
                    placeholder={translate('profileScreen.firstName')}
                  />
                </div>
              </div>
              <div className='w-full flex flex-col gap-2'>
                <div className='flex justify-between items-center'>
                  <label
                    htmlFor='lName'
                    className='text-nell-blue 
                font-light capitalize text-sm'
                  >
                    {translate('profileScreen.lastName')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    id='lName'
                    value={userDetails.lastName}
                    disabled={!isEditableMode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUserDetails({ ...userDetails, lastName: e.target.value })
                    }
                    className='tw-input'
                    style={{ textAlign: 'left' }}
                    placeholder={translate('profileScreen.lastName')}
                  />
                </div>
              </div>
              <div className='w-full flex flex-col gap-2'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='phone' className='text-nell-blue font-light capitalize text-sm'>
                    {translate('profileScreen.phoneNumber')}
                  </label>
                </div>
                <div className='mt-1'>
                  <div className='relative'>
                    <div className='flex gap-2'>
                      {!isEditableMode ? (
                        <input
                          type='text'
                          name='phone'
                          id='phone'
                          disabled={!isEditableMode}
                          value={userDetails.phone}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPhoneNumber(e.target.value)
                          }
                          className={`tw-input relative ${isEditableMode ? '!pl-32' : ''}`}
                          style={{ textAlign: 'left' }}
                          placeholder={translate('profileScreen.phoneNumber')}
                        />
                      ) : (
                        <>
                          <span className='absolute left-2 top-[6px] z-10'>
                            <PhoneInput
                              country={'us'}
                              buttonStyle={{
                                background: '#171717',
                                border: 'none',
                                bottom: '2px',
                                top: '2px',
                                left: '2px',
                              }}
                              containerStyle={{
                                width: `${innerWidth < 640 ? ' 94px' : '88px'}`,
                                margin: 'auto',
                              }}
                              dropdownStyle={{ color: 'white', background: '#161919' }}
                              value={phoneCode}
                              onChange={(phone: string) => setPhoneCode(phone)}
                              inputProps={{
                                className:
                                  'block w-full border-0 bg-nell-dark-grey focus:border-white focus:ring-0 sm:text-sm text-right !pl-10 focus::!bg-black',
                              }}
                            />
                          </span>
                          <input
                            type='text'
                            name='phone'
                            id='phone'
                            disabled={!isEditableMode}
                            value={phoneNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                                setPhoneNumber(e.target.value);
                              }
                            }}
                            className={`tw-input relative ${isEditableMode ? '!pl-32' : ''}`}
                            style={{ textAlign: 'left' }}
                            placeholder={translate('profileScreen.phoneNumber')}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-7 w-full flex  flex-col'>
              <button
                type='button'
                className='tw-button w-full md:!w-[153px] !h-[48px] md:self-end'
                onClick={() => {
                  onSaveAccountSettings();
                }}
              >
                {isLoading && <Spiner className='ml-24' />}
                {translate(isEditableMode ? 'common.save' : 'common.edit')}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='px-4 pb-4 sm:px-0'>
      {_renderAccountTab()}
      {isEmailVisible && (
        <ChangeEmail
          isLoading={isLoading}
          isEmailVisible={isEmailVisible}
          setIsEmailVisible={setIsEmailVisible}
          isEmailCodeVisible={isEmailCodeVisible}
          setIsEmailCodeVisible={setIsEmailCodeVisible}
          emailDetails={emailDetails}
          setEmailDetails={setEmailDetails}
          onChangeEmail={onChangeEmail}
          onValidateEmailCode={onValidateEmailCode}
          code={code}
          setCode={setCode}
        />
      )}
      {isPasswordVisible && (
        <ChangePassword
          isLoading={isLoading}
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVisible={setIsPasswordVisible}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          onSaveChangePassword={onSaveChangePassword}
        />
      )}
    </div>
  );
};
