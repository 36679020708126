import axios from 'axios';

export const KbServices = {
  tagOptions: async (): Promise<any> => {
    try {
      const endPoint = `kb/factory/tag/dropdown/options`;
      const factoryTags = await axios.get(endPoint);
      return factoryTags;
    } catch (error) {
      throw error;
    }
  },
};
