import React from 'react';
import ArrowRightIcon from 'assets/images/ArrowRightIcon.svg';
import { calcPagination } from 'utils/helperFunctions';

type Params = {
  skip: number;
  limit: number;
};
type Props = {
  onChange?: (params: Params) => void;
  count: number;
  limit: number;
  skip: number;
  className?: string;
  calc?: (page?: number, per_page?: number) => { skip: number; limit: number };
};

export const Pagination: React.FC<Props> = React.memo(
  ({ onChange, count, limit, skip, className = '', calc = calcPagination }) => {
    const totalPages = limit === 0 ? 0 : Math.ceil(count / limit);
    const currentPage = limit === 0 ? 0 : skip / limit + 1;

    return (
      <div className={className}>
        <div className='flex items-center space-x-2 justify-end pr-9 pb-7'>
          {currentPage > 1 && (
            <div>
              <button
                type='button'
                onClick={() => onChange && onChange({ ...calc(currentPage - 1) })}
                className='inline-flex justify-center items-center rounded-[4.8px] border-[1.2px] border-nell-blue w-6 h-7 border-opacity-[0.28]'
              >
                <img src={ArrowRightIcon} className='rotate-180' alt='Left' />
              </button>
            </div>
          )}
          <div>
            <div className='text-white text-[17px]'>{`${currentPage || 1} of ${
              totalPages || 1
            }`}</div>
          </div>
          {currentPage < totalPages && (
            <div>
              <button
                type='button'
                onClick={() => onChange && onChange({ ...calc(currentPage + 1) })}
                className='inline-flex justify-center items-center rounded-[4.8px] border-[2px] border-nell-blue w-6 h-7 border-opacity-[0.28]'
              >
                <img src={ArrowRightIcon} alt='Right' />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);
