import { Select } from 'components/shared';

type Props = {
  onChange: (keys: any) => void;
  filter: any;
  setNameSort?: any;
  setEmailSort?: any;
  adminGroups?: any[];
  clasName?: string;
};

const UsersFilter = (props: Props) => {
  const { onChange, filter, adminGroups, clasName } = props;

  const onChangeFilter = (newFilters = {}) => {
    onChange(newFilters);
  };
  return (
    <div className={`${clasName} flex gap-4 flex-wrap`}>
      <div className='min-w-[120px]'>
        <Select
          options={[
            { value: 'asc', label: 'From A-Z' },
            { value: 'desc', label: 'From Z-A' },
          ]}
          onChange={e => {
            if (e?.value === undefined || e === undefined) {
              delete filter['sortBy[0][field]'];
              delete filter['sortBy[0][type]'];
              delete filter['sortBy[1][field]'];
              delete filter['sortBy[1][type]'];
              onChangeFilter({ skip: 0 });
            } else {
              onChangeFilter({
                skip: 0,
                'sortBy[0][field]': 'firstName',
                'sortBy[0][type]': e?.value,
                'sortBy[1][field]': 'lastName',
                'sortBy[1][type]': e?.value,
              });
            }
          }}
          value={filter['sortBy[0][field]'] === 'firstName' ? filter['sortBy[0][type]'] : undefined}
          toClearFilter={true}
          placeholder='Name'
        />
      </div>
      <div className='min-w-[120px]'>
        <Select
          options={[
            { value: 'asc', label: 'From A-Z' },
            { value: 'desc', label: 'From Z-A' },
          ]}
          onChange={e => {
            if (e?.value === undefined || e === undefined) {
              delete filter['sortBy[0][field]'];
              delete filter['sortBy[0][type]'];
              delete filter['sortBy[1][field]'];
              delete filter['sortBy[1][type]'];
              onChangeFilter({ skip: 0 });
            } else {
              onChangeFilter({
                skip: 0,
                'sortBy[0][field]': 'email',
                'sortBy[0][type]': e?.value,
              });
            }
          }}
          value={filter['sortBy[0][field]'] === 'email' ? filter['sortBy[0][type]'] : undefined}
          toClearFilter={true}
          placeholder='Email'
        />
      </div>

      <div className='min-w-[120px]'>
        <Select
          options={
            (adminGroups && adminGroups.map(grp => ({ value: grp.code, label: grp.name }))) || []
          }
          onChange={e => {
            onChangeFilter({ skip: 0, role: e?.value === 'all' ? undefined : e?.value });
          }}
          value={filter?.role || 'all'}
          placeholder='Role'
        />
      </div>
    </div>
  );
};

export default UsersFilter;
