import { translate } from 'utils/helperFunctions';
import Logo from 'assets/images/GuestLogo.png';

export const ComingSoon = () => {
  return (
    <div className='flex flex-col justify-center items-center' style={{ minHeight: '87.5vh' }}>
      <div className='flex-shrink-0'>
        <img src={Logo} />
      </div>
      <h1 className='font-black text-white text-3xl sm:text-[42px] mt-4'>
        {translate('common.comingSoon')}
      </h1>
    </div>
  );
};
