import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { Dashboard, DashboardLayout } from 'screens/Dashboard';
import { Orders, OrdersDetails } from 'screens/Orders';
import { Page404 } from 'Pages';
import { Login } from 'screens/Login';
import { useStore } from 'services/context';
import { MyProfile } from 'screens/MyProfile';
import { Settings } from 'screens/Settings';
import { Tags, TagsDetails } from 'screens/Tags';
import { DownLinks, DownLinksDetails } from 'screens/DownLinks';
import { FactoryTags, TagDetails } from 'screens/FactoryTags';
import { CreateOrganization, Organization, OrganizationDetailsSection } from 'screens/Organization';

const publicRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
];

const privateRoutes = [
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/dashboard/orders',
        element: <Orders />,
      },
      {
        path: '/dashboard/orders/:id',
        element: <OrdersDetails />,
      },
      {
        path: '/dashboard/factorytags',
        element: <FactoryTags />,
      },
      {
        path: '/dashboard/factorytags/:devEUI',
        element: <TagDetails />,
      },
      {
        path: '/dashboard/tags',
        element: <Tags />,
      },
      {
        path: '/dashboard/tags/:id',
        element: <TagsDetails />,
      },
      {
        path: '/dashboard/downlinks',
        element: <DownLinks />,
      },
      {
        path: '/dashboard/downlinks/:id',
        element: <DownLinksDetails />,
      },
      {
        path: '/dashboard/organizations',
        element: <Organization />,
      },
      {
        path: '/dashboard/organizations/:id',
        element: <OrganizationDetailsSection />,
      },
      {
        path: '/dashboard/organizations/create/:id',
        element: <CreateOrganization />,
      },
      {
        path: '/dashboard/profile',
        element: <MyProfile />,
      },
      {
        path: '/dashboard/settings',
        element: <Settings />,
      },
    ],
  },
];

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  return children;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }
  return children;
};

const AppRouter = () => {
  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          {/* Start Public Routes */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PublicRoute key={index}>{route.component}</PublicRoute>}
            />
          ))}
          {/* End Public Routes */}

          {/* Start Private Routes */}
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute>{route.element}</PrivateRoute>}
            >
              {route.children.map((childRoute, cIndex) => (
                <Route
                  key={`${index}-${cIndex}`}
                  path={childRoute.path}
                  element={<PrivateRoute>{childRoute.element}</PrivateRoute>}
                />
              ))}
            </Route>
          ))}
          {/* End Private Routes */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;
