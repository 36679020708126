import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

type ModalProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: number | string;
  modalTitle?: string;
  hideXIcon?: boolean;
};
export const Modal: React.FC<ModalProps> = props => {
  const RefDiv = useRef(null);

  return (
    <Transition.Root show={props.visible} as={Fragment}>
      <Dialog
        as='div'
        initialFocus={RefDiv}
        className='fixed z-[1000] inset-0 overflow-y-auto'
        onClose={() => {}}
      >
        <div className='flex items-center justify-center text-nell-white min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-nell-black bg-opacity-[.95] transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:min-h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              ref={RefDiv}
              style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)', width: props.width }}
              className={`bg-nell-black inline-block border-nell-blue border-2 rounded-[20px] align-bottom xpx-2 pb-6 md:pb-10 text-left transform transition-all sm:my-8 sm:align-middle xoverflow-x-hidden ${
                !!props.width ? '' : 'sm:max-w-xl w-[300px] md:w-[500px]'
              } `}
            >
              <div className='border-b-[1px] border-nell-light-grey flex items-center justify-between px-4 md:pl-9 md:pr-4 mb-6 h-16'>
                {props.modalTitle && (
                  <div className='font-prototype text-2xl xfont-normal'>{props.modalTitle}</div>
                )}
                <button
                  type='button'
                  className={`${!!props.hideXIcon ? 'hidden' : ''} text-right`}
                  onClick={() => props.onClose()}
                >
                  <XIcon className='w-6 h-6 stroke-3' />
                  {/* <IoMdClose className='text-3xl stroke-[25px] ' /> */}
                </button>
              </div>

              {props.children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
