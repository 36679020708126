import axios from 'axios';
import store from 'services/context/store';
import { LoginParams } from '.';
import { setStorageLoginData, getStorageLoginData } from 'utils/StorageServices';
import { setLoginData } from 'services/context/Auth/actions';
export interface ReAuthData {
  access_token: string;
  refresh_token: string;
}

export const AuthServices = {
  login: async (payload: LoginParams): Promise<any> => {
    const endPoint = `oauth/token`;
    try {
      const login = await axios.post<object, ReAuthData>(endPoint, {
        ...payload,
        grant_type: 'password',
      });

      setStorageLoginData(login);
      store.dispatch(setLoginData({ ...login }));

      return login;
    } catch (error) {
      throw error;
    }
  },
  refreshTokens: async () => {
    try {
      const refreshToken = getStorageLoginData('refresh_token');
      const newTokens: ReAuthData = await axios.post('oauth/token', {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      });
      if (newTokens?.access_token) {
        const prevLoginData = getStorageLoginData();
        const tokens = { ...prevLoginData, ...newTokens };
        setStorageLoginData(tokens);
        store.dispatch(setLoginData(tokens));
        return true;
      } else {
        throw new Error('Unable to refresh tokens');
      }
    } catch (error) {
      console.log('error on refreshTokens', error);
      throw error;
    }
  },
  logout: async (): Promise<any> => {
    const access_token = getStorageLoginData('access_token');
    const refresh_token = getStorageLoginData('refresh_token');

    if (!refresh_token && !access_token) {
      throw new Error('No refresh token or access token found');
    }

    const accessTokenEndPoint = `oauth/accessToken/${access_token}`;
    const refreshTokenEndPoint = `oauth/refreshToken/${refresh_token}`;

    try {
      if (refresh_token) {
        await axios.delete(refreshTokenEndPoint);
      }
      if (access_token) {
        await axios.delete(accessTokenEndPoint);
      }
      return;
    } catch (error) {
      console.log('error on logout', error);
    }
  },
};
