import axios from 'axios';
import { Tag } from './Tag.types';

type GeneralParams = {
  [key: string]: any;
};

export const TagServices = {
  fetchFactoryTags: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `tag/factory/tags/search`;
      const factoryTags = await axios.get(endPoint, { params });
      return factoryTags;
    } catch (error) {
      throw error;
    }
  },
  addNewTag: async (payload: Tag): Promise<any> => {
    const endPoint = `tag/factory/tag`;
    try {
      const newTag = await axios.post(endPoint, payload);
      return newTag;
    } catch (error) {
      throw error;
    }
  },
  addNewBulkTags: async (payload: { [key: string]: any }): Promise<any> => {
    const endPoint = `tag/factory/tags`;
    try {
      const newBulkTags = await axios.post(endPoint, payload);
      return newBulkTags;
    } catch (error) {
      throw error;
    }
  },
  fetchUnrestrictedFactoryTags: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `tag/factory/tags/search/unrestricted`;
      const factoryTags = await axios.get(endPoint, {
        params,
      });

      return factoryTags;
    } catch (error) {
      throw error;
    }
  },
  fetchTagDetails: async (devEUI: string): Promise<any> => {
    try {
      const endPoint = `tag/factory/tag/${devEUI}/unrestricted`;
      const factoryTags = await axios.get(endPoint);
      return factoryTags;
    } catch (error) {
      throw error;
    }
  },
  fetchDownLinks: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `tag/search/downlinks`;
      const downLinks = await axios.get(endPoint, { params });
      return downLinks;
    } catch (error) {
      throw error;
    }
  },
};
