const APIs: any = {
  abilities: {
    oauth: {
      post: {
        '/token': {
          I: 'token',
          A: 'post',
          M: 'Create a token through login',
        },
      },
      delete: {
        '/accessToken/:token': {
          I: 'accessToken',
          A: 'delete',
          M: 'delete accessToken ',
        },
        '/refreshToken/:token': {
          I: 'refreshToken',
          A: 'delete',
          M: 'delete refreshToken',
        },
      },
    },
    urac: {
      get: {
        '/password/forgot/code': {
          I: 'code',
          A: 'get',
          M: 'Get code to verify forgot password',
        },
        '/user/me': {
          I: 'me',
          A: 'get',
          M: 'get user data',
        },
        '/validate/changeEmail': {
          I: 'changeEmail',
          A: 'get',
          M: 'validate change email',
        },
        '/admin/groups': {
          I: 'adminGroups',
          A: 'get',
          M: 'get admin groups',
        },
        '/admin/group': {
          I: 'adminGroup',
          A: 'get',
          M: 'get admin group By id',
        },
      },
      post: {
        '/admin/group': {
          I: 'adminGroup',
          A: 'post',
          M: 'post admin group',
        },
      },
      put: {
        '/account': {
          I: 'account',
          A: 'put',
          M: 'Edit account information by id',
        },
        '/account/email/code': {
          I: 'email',
          A: 'put',
          M: 'Change account email by id - a code will be emailed',
        },
        '/account/password': {
          I: 'passwordAccount',
          A: 'put',
          M: 'Change password  by code',
        },
        '/password/reset': {
          I: 'password',
          A: 'put',
          M: 'Change password  by code',
        },
        '/admin/group': {
          I: 'adminGroup',
          A: 'put',
          M: 'put admin group By id',
        },
      },
      delete: {
        '/admin/group': {
          I: 'adminGroup',
          A: 'delete',
          M: 'delete admin group By id',
        },
      },
    },
    organization: {
      get: {
        '/companies/:types': {
          I: 'companies',
          A: 'get',
          M: 'get all companies types',
        },
        '/company/:companyId': {
          I: 'company',
          A: 'get',
          M: 'Get company by id',
        },
        '/company/:companyId/groups': {
          I: 'companyGroups',
          A: 'get',
          M: 'Get company groups',
        },
      },
      post: {
        '/company/:type': {
          I: 'company',
          A: 'post',
          M: 'post company by providing type',
        },
      },
      put: {
        '/company/:companyId/status': {
          I: 'companyStatus',
          A: 'put',
          M: 'put  company status',
        },
        '/company/:companyId': {
          I: 'company',
          A: 'put',
          M: 'put  company',
        },
        '/company/:companyId/user/:userId/groups': {
          I: 'companyUserGroups',
          A: 'put',
          M: 'put company user groups ',
        },
      },
      delete: {
        '/company/:companyId/user/:userId': {
          I: 'companyUser',
          A: 'delete',
          M: 'delete user under company',
        },
      },
    },
    onboarding: {
      get: {
        '/admin/users/search': {
          I: 'users',
          A: 'get',
          M: 'get users under admin',
        },
        '/company/:companyId/users': {
          I: 'companyUsers',
          A: 'get',
          M: 'get users under company',
        },
      },
      delete: {
        '/admin/user/:id': {
          I: 'deleteUser',
          A: 'delete',
          M: 'delete user under admin',
        },
      },
      post: {
        '/admin/user': {
          I: 'addUser',
          A: 'post',
          M: 'Create user under admin',
        },
        '/company/:companyId/user': {
          I: 'addCompanyUser',
          A: 'post',
          M: 'Create user under company',
        },
      },
    },
    tag: {
      get: {
        '/factory/tags/search/unrestricted': {
          I: 'searchTags',
          A: 'get',
          M: 'get tags under search',
        },
        ' /factory/tag/:devEUI/unrestricted': {
          I: 'getTagDevEUI',
          A: 'get',
          M: 'get devEUI tags',
        },
        '/search/unrestricted': {
          I: 'searchUnrestricted',
          A: 'get',
          M: 'get unrestricted',
        },
        '/factory/tag/:devEUI/unrestricted': {
          I: 'getUnrestricedDevEUI',
          A: 'get',
          M: 'get UnrestricedDevEUI',
        },
        '/:devEUI/unrestricted/admin': {
          I: 'getUnrestrictedAdmin',
          A: 'get',
          M: 'get unrestrictedAdmin',
        },
        '/:devEUI/network/unrestricted': {
          I: 'getUnrestrictedNetwork',
          A: 'get',
          M: 'get unrestrictedNetwork',
        },
        '/:devEUI/downlinks/unrestricted': {
          I: 'getUnrestrictedDownlinks',
          A: 'get',
          M: 'get unrestrictedDownlinks',
        },
        '/:devEUI/network/events/unrestricted': {
          I: 'getUnrestrictedNetworkEvents',
          A: 'get',
          M: 'get UnrestrictedNetworkEvents',
        },
      },
      post: {
        '/factory/tag': {
          I: 'addTag',
          A: 'post',
          M: 'Create tag',
        },
        '/factory/tags': {
          I: 'addTags',
          A: 'post',
          M: 'Create tags',
        },
      },
      put: {
        '/downlinks...': {
          I: 'putDownlinks',
          A: 'put',
          M: 'put downlinks',
        },
      },
    },
    kb: {
      get: {
        '/factory/tag/dropdown/options': {
          I: 'tagOptions',
          A: 'get',
          M: 'get tags options',
        },
      },
    },
    commerce: {
      get: {
        '/orders/:devEUI/unrestricted': {
          I: 'UnrestricedDevEUiOrders',
          A: 'get',
          M: 'get UnrestricedDevEUiOrders',
        },
        '/orders/shop': {
          I: 'shopOrders',
          A: 'get',
          M: 'get shop orders',
        },
        '/order/:id': {
          I: 'OrderByID',
          A: 'get',
          M: 'get OrderByID',
        },
      },
    },
    analytics: {
      get: {
        '/tag/:devEUI/analytics/unrestricted': {
          I: 'UnrestricedDevEUiAnalytics',
          A: 'get',
          M: 'get tag UnrestricedDevEUiAnalytics',
        },
      },
    },
  },
  versions: {
    urac: '3',
    oauth: '1',
    organization: '1',
    onboarding: '2',
  },
};

export default APIs;
