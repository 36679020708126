import axios from 'axios';
import {
  getStorageUserData,
  getUserDataByKey,
  getUserKeyACL,
  setStorageUserData,
  setUserKeyACL,
} from 'utils/StorageServices';
import { ChangePasswordProperties, User, UserAccountProperties } from '../Auth';
import { updateUserAccount } from 'services/context/Auth/actions';
import store from 'services/context/store';

type GeneralParams = {
  [key: string]: any;
};

export const UracServices = {
  fetchUser: async (): Promise<any> => {
    const endPoint = `urac/user/me`;
    try {
      const uracUser = await axios.get<object, User>(endPoint);
      setStorageUserData(uracUser);
      store.dispatch(updateUserAccount(uracUser));
      return uracUser;
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (payload: ChangePasswordProperties): Promise<any> => {
    const endPoint = `urac/account/password`;
    try {
      const id: string = getUserDataByKey('_id');
      await axios.put(endPoint, { id: id, ...payload });
      return true;
    } catch (error) {
      throw error;
    }
  },
  updateAccount: async (payload: Partial<UserAccountProperties>): Promise<any> => {
    const endPoint = `urac/account`;
    try {
      const id: string = getUserDataByKey('_id');
      const userData = await axios.put(endPoint, {
        id,
        ...payload,
      });
      const prevUserData = getStorageUserData();

      const newData = { ...userData, ...prevUserData, ...payload };

      setStorageUserData(newData);
      store.dispatch(updateUserAccount(newData));

      return newData;
    } catch (error) {
      throw error;
    }
  },
  forgotPasswordCode: async (username: string): Promise<any> => {
    const endPoint = `urac/password/forgot/code?username=${username}`;
    try {
      const forgotCode = await axios.get(endPoint);
      return forgotCode;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (payload: any): Promise<any> => {
    const endPoint = `urac/password/reset`;
    try {
      const restPassword = await axios.put(endPoint, payload);
      return restPassword;
    } catch (error) {
      throw error;
    }
  },
  changeEmail: async (email: string): Promise<any> => {
    const endPoint = `urac/account/email/code`;
    const id: string = getUserDataByKey('_id');
    try {
      const response = await axios.put(endPoint, { email, id });
      return response;
    } catch (error) {
      throw error;
    }
  },
  validateChangeEmailCode: async (code: string): Promise<any> => {
    const endPoint = `urac/validate/changeEmail?token=${code}`;
    try {
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchAdminGroups: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `urac/admin/groups`;
      const adminGroups = await axios.get(endPoint, { params });
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  deleteAdminUser: async (userId: string): Promise<boolean> => {
    const endPoint = `urac/admin/user?id=${userId}`;
    try {
      await axios.delete(endPoint);
      return true;
    } catch (error) {
      throw error;
    }
  },
  updateAdminGroups: async (body: any): Promise<any> => {
    try {
      const endPoint = `urac/admin/user/groups`;
      const adminGroups = await axios.put(endPoint, body);
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  fetchAdminGroupDetail: async (groupId: string): Promise<any> => {
    const endPoint = `urac/admin/group?id=${groupId}`;
    try {
      const detail = await axios.get(endPoint);

      return detail;
    } catch (error) {
      throw error;
    }
  },
  addAdminGroup: async (payload: any): Promise<any> => {
    try {
      const endPoint = `urac/admin/group`;
      const adminGroups = await axios.post(endPoint, payload);
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  editAdminGroup: async (payload: any): Promise<any> => {
    try {
      const endPoint = `urac/admin/group`;
      const adminGroups = await axios.put(endPoint, payload);
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  deleteAdminGroup: async (id: string): Promise<any> => {
    try {
      const endPoint = `urac/admin/group?id=${id}`;
      const adminGroups = await axios.delete(endPoint);
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  getUserACL: async (extKey?: any) => {
    let cacheIndex = '0000000000000000';
    if (extKey) {
      cacheIndex = extKey.substr(extKey.length - 16);
    }

    const acl_cached = getUserKeyACL(cacheIndex);
    if (acl_cached) {
      return acl_cached;
    }
    let opt: any = null;
    if (extKey) {
      opt = {
        headers: { key: extKey },
      };
    }
    const aclKey = await axios.get('/soajs/acl', opt);
    if (aclKey) {
      setUserKeyACL(aclKey, cacheIndex);
    }
    return aclKey;
  },
};
