import { useEffect, useState, Fragment } from 'react';
import {
  calcPagination25,
  scrollTop,
  getStatusCardClass,
  getStatusClass,
  translate,
} from 'utils/helperFunctions';
import { Pagination, Select, Table, Modal } from 'components/shared';
import { toast } from 'react-toastify';
import { BsPlus } from 'react-icons/bs';
import { SearchIcon } from '@heroicons/react/outline';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { TagServices } from 'services/apis/Tag';
import FactoryTagsFilter from './FactoryTagsFilter';
import { AddTag } from './AddTag';
import { useNavigate } from 'react-router-dom';
import { AddBulkTags } from './AddBulkTags';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Tag } from 'services/apis/Tag/Tag.types';
import { ICompany, OrganizationServices } from 'services/apis/Organization';

export type CompanyOption = {
  _id: string;
  name: string;
};

let debounce: number;
export const FactoryTags = () => {
  const navigate = useNavigate();
  const [factorytag, setFactoryTags] = useState<Tag[]>([]);
  const [isVisibleAddTag, setIsVisibleAddTag] = useState<boolean>(false);
  const [isVisibleAddBulkTags, setIsVisibleAddBulkTags] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<{ [key: string]: any }>({});
  const [companies, setCompany] = useState<ICompany[]>([]);
  const [newTag, setNewTag] = useState<Tag>({
    appEUI: '',
    appKey: '',
    batteryType: '',
    devEUI: '',
    driver: '',
    network: '',
    networkConnectionId: '',
    region: '',
    serialnumber: '',
    companyId: '',
  });
  const [newBulkTags, setNewBulkTags] = useState<{ [key: string]: any }>({ tags: [] });
  const [writeError, setWriteError] = useState<{ [key: string]: any }>({});
  const [isWriteErrorVisible, setIsWriteErrorVisible] = useState<boolean>(false);
  const [totalTags, setTotalTags] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [devEUISort, setdevEUISort] = useState<'asc' | 'desc' | undefined>(undefined);
  const [serialNumberSort, setSerialNumberSort] = useState<'asc' | 'desc' | undefined>(undefined);

  const fetchUnrestrictedFactoryTags = async (params = calcPagination25()) => {
    try {
      const tags = await TagServices.fetchUnrestrictedFactoryTags(params);
      setTotalTags(tags.count);
      setFactoryTags(tags.items);
      setCurrentPage(params.skip / params.limit + 1);
    } catch (err) {
      throw err;
    }
  };

  const fetchCompanies = async (params = {}) => {
    try {
      const companiesList = await OrganizationServices.fetchCompanies('company', params);
      setCompany(companiesList.items);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchCompanies();
    if (sessionStorage.getItem('fTagsFilters') !== null) {
      const fTagsFilters = JSON.parse(sessionStorage.getItem('fTagsFilters') || '{}');
      if (fTagsFilters['sortBy[0][field]'] === 'devEUI') {
        setdevEUISort(fTagsFilters['sortBy[0][type]']);
      } else if (fTagsFilters['sortBy[0][field]'] === 'serialnumber') {
        setSerialNumberSort(fTagsFilters['sortBy[0][type]']);
      }
      setFilter(fTagsFilters);
      fetchUnrestrictedFactoryTags({ ...fTagsFilters, limit: 25 });
    } else {
      fetchUnrestrictedFactoryTags();
    }
  }, []);

  const onChangeFilter = (newFilters = {}) => {
    sessionStorage.setItem('fTagsFilters', JSON.stringify({ ...filter, ...newFilters } || {}));
    setFilter({ ...filter, ...newFilters });
    fetchUnrestrictedFactoryTags({ ...calcPagination25(), ...filter, ...newFilters });
    scrollTop();
  };

  const onAddTag = async () => {
    setIsLoading(true);
    try {
      await TagServices.addNewTag(newTag);
      setIsLoading(false);
      setIsVisibleAddTag(false);
      fetchUnrestrictedFactoryTags();
      toast.success(translate('factoryTags.addNewTagToast'));
      setNewTag({
        appEUI: '',
        appKey: '',
        batteryType: '',
        devEUI: '',
        driver: '',
        network: '',
        networkConnectionId: '',
        region: '',
        serialnumber: '',
        status: '',
        companyId: '',
      });
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  const onAddBulkTags = async () => {
    setIsLoading(true);
    try {
      const res = await TagServices.addNewBulkTags({ ...newBulkTags });
      if (res.acknowledged === true) {
        toast.success(translate('factoryTags.addNewBulkTagToast'));
      } else if (res.result && res.result.writeErrors) {
        setWriteError(res);
        setIsWriteErrorVisible(true);
      }
      setIsLoading(false);
      fetchUnrestrictedFactoryTags();
      setNewBulkTags({ tags: [] });
      setIsVisibleAddBulkTags(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  const CompaniesOption = () => {
    return companies?.map((opt: CompanyOption) => ({ label: opt.name, value: opt._id })) || [];
  };

  const _renderWriteErrorModal = () => {
    return (
      <Modal
        visible={isWriteErrorVisible}
        modalTitle={'New Bulk Tags Error'}
        onClose={() => {
          setNewBulkTags({ tags: [] });
          setIsWriteErrorVisible(false);
        }}
      >
        <div className='flex flex-col gap-5 justify-start px-4 sm:px-6 '>
          <div>
            <div className=''>
              {translate('factoryTags.totalUploaded')}: {writeError?.total}
            </div>
            <div>
              {translate('factoryTags.totalFailed')}: {writeError?.total - writeError?.success}
            </div>
            <hr className='border-1 mt-5 mb-4 border-nell-light-grey w-full' />
            <div className='max-h-[200px] overflow-y-auto'>
              {writeError?.result?.writeErrors.map((err: any, idx: number) => {
                return (
                  <div className='px-5' key={idx}>
                    {translate('factoryTags.devEUI')}: {err?.op?.devEUI}
                  </div>
                );
              })}
            </div>
            <hr className='border-1 mt-4 mb-7 border-nell-light-grey w-full' />
          </div>
        </div>
        <div className='flex gap-5 items-center justify-center px-4 sm:px-6'>
          <button
            className='tw-button w-full'
            onClick={() => {
              setIsWriteErrorVisible(false);
              setIsVisibleAddBulkTags(true);
            }}
          >
            {translate('common.upload')}
          </button>
          <button
            className='tw-button w-full !bg-nell-red'
            onClick={() => {
              setIsWriteErrorVisible(false);
            }}
          >
            {translate('common.cancel')}
          </button>
        </div>
      </Modal>
    );
  };

  const AddTagDropMenue = () => {
    return (
      <div className='hidden sm:block fixed bottom-10 sm:bottom-auto right-6 sm:absolute sm:top-6 text-right z-30'>
        <Menu as='div' className='relative inline-block text-left'>
          <div>
            <Menu.Button className='tw-button  !border-0 sm:!border-2 w-14 sm:!w-full h-14 sm:!pr-4 !p-0 !rounded-lg xinline-flex xw-full xjustify-center xrounded-md xbg-black xbg-opacity-20 xpx-4 xpy-2 xtext-sm xfont-medium xtext-white xhover:bg-opacity-30 xfocus:outline-none xfocus-visible:ring-2 xfocus-visible:ring-white xfocus-visible:ring-opacity-75'>
              <BsPlus className='text-5xl' />
              <span className='hidden sm:block'>{translate('factoryTags.newTag')}</span>
              <ChevronDownIcon
                className='hidden sm:block ml-2 -mr-1 h-8 w-8 text-violet-200 hover:text-violet-100'
                aria-hidden='true'
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 -mt-36 sm:mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-nell-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='px-1 py-1 '>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-nell-blue text-nell-white ' : 'text-nell-white'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => {
                        setIsVisibleAddTag(true);
                      }}
                    >
                      {translate('factoryTags.newTag')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-nell-blue text-nell-white ' : 'text-nell-white'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => {
                        setIsVisibleAddBulkTags(true);
                      }}
                    >
                      {translate('factoryTags.newBulkTags')}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    );
  };

  const _renderFactoryTagsForMobile = () => {
    return factorytag?.map((tag: Tag) => {
      return (
        <div
          key={tag?.devEUI}
          className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-4 flex items-start justify-between xmin-h-[105px] cursor-pointer mb-4'
          onClick={() => {
            navigate(`/dashboard/factorytags/${tag?.devEUI}`);
          }}
        >
          <div className='xflex xjustify-between'>
            <h2 className='font-heavyGaret capitalize '>{tag?.devEUI || '-'}</h2>
            <p className=''>{tag?.serialnumber || '-'}</p>
            <p className=''>{tag?.networkConnectionInfo?.name || '-'}</p>
          </div>
          <div
            className={`${
              tag?.status ? getStatusCardClass(tag.status) : ''
            } w-24 rounded-2xl text-center text-sm text-nell-black font-heavyGaret capitalize p-1`}
          >
            {tag?.status || '-'}
          </div>
        </div>
      );
    });
  };

  const _renderFactoryTags = () => {
    const columns = [
      {
        title: (
          <div className='flex flex-col items-start gap-2'>
            <div className='flex items-center'>
              <span>{translate('factoryTags.devEUI')}</span>{' '}
              <span className='flex flex-col'>
                <MdKeyboardArrowUp
                  onClick={() => {
                    setSerialNumberSort(undefined);
                    if (devEUISort !== 'desc') {
                      setdevEUISort('desc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'devEUI',
                        'sortBy[0][type]': 'desc',
                      });
                    } else {
                      setdevEUISort(undefined);
                      delete filter['sortBy[0][field]'];
                      delete filter['sortBy[0][type]'];
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl cursor-pointer ${
                    devEUISort === 'desc' ? 'text-nell-blue' : ''
                  }`}
                />
                <MdKeyboardArrowUp
                  onClick={() => {
                    setSerialNumberSort(undefined);
                    if (devEUISort !== 'asc') {
                      setdevEUISort('asc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'devEUI',
                        'sortBy[0][type]': 'asc',
                      });
                    } else {
                      setdevEUISort(undefined);
                      delete filter['sortBy[0][field]'];
                      delete filter['sortBy[0][type]'];
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl rotate-180 -mt-3 cursor-pointer ${
                    devEUISort === 'asc' ? 'text-nell-blue' : ''
                  } `}
                />
              </span>
            </div>
          </div>
        ),
        dataIndex: 'devEUI',
        render: (devEUI: string) => (
          <div className='break-all whitespace-normal flex items-center cursor-pointer'>
            {devEUI}
          </div>
        ),
      },
      {
        title: (
          <div className='flex items-center gap-2'>
            <div className='flex items-center'>
              <span>{translate('factoryTags.serialNumber')}</span>{' '}
              <span className='flex flex-col'>
                <MdKeyboardArrowUp
                  onClick={() => {
                    setdevEUISort(undefined);
                    if (serialNumberSort !== 'desc') {
                      setSerialNumberSort('desc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'serialnumber',
                        'sortBy[0][type]': 'desc',
                      });
                    } else {
                      setSerialNumberSort(undefined);
                      delete filter['sortBy[0][field]'];
                      delete filter['sortBy[0][type]'];
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl cursor-pointer ${
                    serialNumberSort === 'desc' ? 'text-nell-blue' : ''
                  }`}
                />
                <MdKeyboardArrowUp
                  onClick={() => {
                    setdevEUISort(undefined);
                    if (serialNumberSort !== 'asc') {
                      setSerialNumberSort('asc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'serialnumber',
                        'sortBy[0][type]': 'asc',
                      });
                    } else {
                      setSerialNumberSort(undefined);
                      delete filter['sortBy[0][field]'];
                      delete filter['sortBy[0][type]'];
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl rotate-180 -mt-3 cursor-pointer ${
                    serialNumberSort === 'asc' ? 'text-nell-blue' : ''
                  } `}
                />
              </span>
            </div>
          </div>
        ),
        dataIndex: 'serialnumber',
        render: (serialnumber: string) => (
          <div className='flex items-center cursor-pointer break-all whitespace-normal'>
            <div>{serialnumber}</div>
          </div>
        ),
      },
      {
        title: translate('factoryTags.networkConnection'),
        dataIndex: 'networkConnectionInfo',
        render: (networkConnectionInfo: any) => {
          return <div>{networkConnectionInfo?.name}</div>;
        },
      },
      {
        title: (
          <div className='w-[120px]'>
            <Select
              options={[
                { value: 'all', label: 'All' },
                { value: 'new', label: 'New' },
                { value: 'pending', label: 'Pending' },
                { value: 'active', label: 'Active' },
                { value: 'deleted', label: 'Deleted' },
              ]}
              onChange={e => {
                if (e?.value === 'all') {
                  delete filter.status;
                  onChangeFilter({ ...filter, skip: 0 });
                } else {
                  onChangeFilter({ ...filter, skip: 0, status: e?.value });
                }
              }}
              value={filter.status}
              placeholder='Status'
              className='bg-nell-dark-grey'
            />
          </div>
        ),
        dataIndex: 'status',
        render: (status: string) => {
          return <div className={`${getStatusClass(status)} capitalize`}>{status}</div>;
        },
      },
    ];

    const { limit, skip } = calcPagination25(currentPage);

    return (
      <>
        {isVisibleAddTag && (
          <AddTag
            getCompanies={CompaniesOption}
            isLoading={isLoading}
            isVisibleAddTag={isVisibleAddTag}
            setIsVisibleAddTag={setIsVisibleAddTag}
            setNewTag={setNewTag}
            newTag={newTag}
            onAddTag={onAddTag}
          />
        )}
        {isVisibleAddBulkTags && (
          <AddBulkTags
            getCompanies={CompaniesOption}
            isLoading={isLoading}
            isVisibleAddBulkTags={isVisibleAddBulkTags}
            setIsVisibleAddBulkTags={setIsVisibleAddBulkTags}
            setNewBulkTags={setNewBulkTags}
            newBulkTags={newBulkTags}
            onAddBulkTags={onAddBulkTags}
          />
        )}
        {isWriteErrorVisible && _renderWriteErrorModal()}
        <div className='pb-4 mt-7' style={{ boxShadow: '0px 2px 8px rgba(2, 1, 1, 0.153)' }}>
          <div className=''>{AddTagDropMenue()}</div>
          <div className='sm:hidden fixed bottom-10 right-6'>
            <button
              type='button'
              className='tw-button !border-0 w-14 h-14 !p-0 !rounded-lg'
              onClick={() => {
                setIsVisibleAddTag(true);
              }}
            >
              <BsPlus className='text-5xl' />
            </button>
          </div>
          <hr className='hidden sm:block border-nell-light-grey mb-8' />
          <div className='relative px-4 mb-4'>
            <div className='absolute inset-y-0 left-4 pl-3 flex items-center pointer-events-none'>
              <SearchIcon className='h-5 w-5 text-nell-light-grey' aria-hidden='true' />
            </div>
            <input
              type='search'
              name='search'
              className='tw-input placeholder:text-xs placeholder:sm:text-sm w-full !rounded-[10px] !pl-10'
              placeholder={translate('factoryTags.searchByDevEUISerialNumberStatus')}
              value={filter?.keywords}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFilter(() => ({ ...filter, keywords: e?.target?.value, skip: 0 }));
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  onChangeFilter({ keywords: e?.target?.value });
                }, 300) as unknown as number;
              }}
            />
          </div>
          <FactoryTagsFilter
            onChange={onChangeFilter}
            filter={filter}
            setdevEUISort={setdevEUISort}
            setSerialNumberSort={setSerialNumberSort}
            clasName='sm:hidden'
          />
          <div className='hidden sm:block w-full'>
            <Table
              columns={columns}
              dataSource={factorytag}
              hover
              onClickRow={(_, tag) => {
                navigate(`/dashboard/factorytags/${tag.devEUI}`);
              }}
            />
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderFactoryTagsForMobile()}</div>
        </div>
        <div className='mb-24'>
          <Pagination
            count={totalTags}
            limit={limit}
            skip={skip}
            calc={calcPagination25}
            onChange={pageParams => {
              onChangeFilter(pageParams);
            }}
            className='sm:mt-5'
          />
        </div>
      </>
    );
  };

  return (
    <div className='relative sm:bg-nell-dark-grey sm:pt-20 mb-10 rounded-[20px] overflow-hidden'>
      {_renderFactoryTags()}
    </div>
  );
};
