import React, { useReducer } from 'react';
import { StoreContext } from './Context';
import AuthReducer, { initStateAuth } from './Auth/reducer';
import globalStore from './store';
import { combineReducers } from '../../utils/helperFunctions';

const initialState = {
  authStore: initStateAuth,
};
const rootReducer = combineReducers({
  authStore: AuthReducer,
});

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const store: any = React.useMemo(() => [state, dispatch], [state]);

  if (!globalStore.isReady) {
    globalStore.isReady = true;
    globalStore.dispatch = params => {
      return dispatch(params);
    };
    Object.freeze(globalStore);
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
