import { useEffect, useState } from 'react';
import {
  scrollTop,
  getStatusCardClass,
  getStatusClass,
  translate,
  calcPagination,
} from 'utils/helperFunctions';
import { CustomDateTimeDisplay, Pagination, Select, Table } from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { TagServices } from 'services/apis/Tag';
import { DownLink } from 'services/apis/Tag/Tag.types';

let debounce: number;

type Filter = {
  keywords: string;
  skip?: number;
  limit?: number;
  status?: string;
};

export const DownLinks = () => {
  const navigate = useNavigate();
  const [downLinks, setDownLinks] = useState<DownLink[]>([]);
  const [filter, setFilter] = useState<Filter>({
    keywords: '',
  });
  const [totalDownlinks, setTotalDownlinks] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const fetchDownLinks = async (params = calcPagination()) => {
    try {
      const list = await TagServices.fetchDownLinks(params);
      setTotalDownlinks(list.count);
      setDownLinks(list.items);
      setCurrentPage(params.skip / params.limit + 1);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchDownLinks();
  }, []);

  const onChangeFilter = (newFilters = {}) => {
    setFilter({ ...filter, ...newFilters });
    fetchDownLinks({ ...calcPagination(), ...filter, ...newFilters });
    scrollTop();
  };

  const _renderOrganizationsForMobile = () => {
    return (
      <div className='flex flex-col gap-4'>
        <div className='self-end'>
          <Select
            options={[
              { value: 'all', label: 'All' },
              { value: 'leaf', label: 'Leaf' },
              { value: 'confirmed', label: 'Confirmed' },
              { value: 'pending', label: 'Pending' },
            ]}
            onChange={e => {
              if (e?.value === 'all') {
                delete filter.status;
                onChangeFilter({ ...filter, skip: 0 });
              } else {
                onChangeFilter({ ...filter, skip: 0, status: e?.value });
              }
            }}
            optionStyle='!pr-0 '
            value={filter.status}
            placeholder='Status'
            className='bg-nell-black !min-w-[100px]'
          />
        </div>
        <div>
          {downLinks?.map(downLink => {
            return (
              <div
                key={downLink?._id}
                className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-2 flex items-start justify-between h-[133px] cursor-pointer mb-4'
                onClick={() => {
                  navigate(`/dashboard/downlinks/${downLink._id}`);
                }}
              >
                <div className='flex justify-between w-full'>
                  <div className='flex flex-col gap-2'>
                    <div className='font-garet text-[16px] font-[850]'>
                      {downLink.devEUI || '-'}
                    </div>
                    <div className='font-garet text-[16px] font-light'>
                      <CustomDateTimeDisplay
                        timeStamp={downLink.time.createdAt}
                        format='DD/MM/YY - HH:mm'
                      />
                    </div>
                    <div className='font-garet text-[16px] font-light'>
                      {downLink.networkConnection}
                    </div>
                    <div className='font-garet text-[16px] font-light'>
                      {downLink.network || '-'}
                    </div>
                  </div>
                  <div
                    className={`${getStatusCardClass(
                      downLink?.status
                    )} shrink-0 w-[92px] h-[38px] flex items-center justify-center rounded-2xl text-center text-sm text-nell-black font-heavyGaret capitalize p-1 flex-col `}
                  >
                    <p>{downLink?.status || '-'}</p>
                    <p>
                      {downLink?.status === 'pending' && (
                        <CustomDateTimeDisplay timeStamp={downLink.time.createdAt} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
          ;
        </div>
      </div>
    );
  };

  const _renderDownLinks = () => {
    const columns = [
      {
        title: (
          <div className='min-w-[78px] text-sm md:text-base !pl-0'>
            {translate('downlinksScreen.devEUI')}
          </div>
        ),
        dataIndex: 'devEUI',
        render: (devEUI: string) => (
          <div className='break-all text-sm md:text-[15px] lg:text-base  whitespace-normal flex items-center cursor-pointer '>
            {devEUI || '-'}
          </div>
        ),
      },
      {
        title: (
          <div className='text-sm md:text-base !pl-0'>
            {translate('downlinksScreen.dateGenerated')}
          </div>
        ),
        dataIndex: 'time',
        render: (_: any, downLink: DownLink) => (
          <div className='break-words whitespace-normal flex items-center cursor-pointer text-sm md:text-[15px] lg:text-base '>
            <CustomDateTimeDisplay
              timeStamp={downLink?.time?.createdAt}
              format='DD/MM/YY - HH:mm'
            />
          </div>
        ),
      },
      {
        title: (
          <div className='text-sm md:text-base !pl-0'>
            {translate('downlinksScreen.networkConnection')}
          </div>
        ),
        dataIndex: 'networkConnection',
        render: (networkConnection: string) => {
          return (
            <div className='flex items-center cursor-pointer break-all text-sm md:text-[15px] lg:text-base  whitespace-normal'>
              {networkConnection || '-'}
            </div>
          );
        },
      },
      {
        title: (
          <div className='text-sm md:text-base !pl-0'>{translate('downlinksScreen.network')}</div>
        ),
        dataIndex: 'network',
        render: (network: string) => (
          <div className='break-words whitespace-normal text-sm md:text-[15px] lg:text-base flex items-center cursor-pointer'>
            {network || '-'}
          </div>
        ),
      },
      {
        title: (
          <div className='w-[100px] md:!w-[120px]'>
            <Select
              options={[
                { value: 'all', label: 'All' },
                { value: 'leaf', label: 'Leaf' },
                { value: 'confirmed', label: 'Confirmed' },
                { value: 'pending', label: 'Pending' },
              ]}
              onChange={e => {
                if (e?.value === 'all') {
                  delete filter.status;
                  onChangeFilter({ ...filter, skip: 0 });
                } else {
                  onChangeFilter({ ...filter, skip: 0, status: e?.value });
                }
              }}
              optionStyle='!pr-0'
              value={filter.status}
              placeholder='Status'
              className='bg-nell-dark-grey'
            />
          </div>
        ),
        dataIndex: 'status',
        theadClass: 'w-0',
        align: 'center',
        render: (status: string, downLink: DownLink) => {
          return (
            <div className={`${getStatusClass(status)} capitalize`}>
              {status || '-'}
              {status === 'pending' && (
                <CustomDateTimeDisplay timeStamp={downLink?.time?.createdAt} format='YY:MM:HH' />
              )}
            </div>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div className='pb-4'>
          <div className='relative px-4 mb-4'>
            <div className='absolute inset-y-0 left-4 pl-3 flex items-center pointer-events-none'>
              <SearchIcon className='h-5 w-5 text-nell-light-grey' aria-hidden='true' />
            </div>
            <input
              type='search'
              name='search'
              className='tw-input placeholder:text-xs placeholder:sm:text-sm w-full !rounded-[10px] !pl-10'
              placeholder={translate('downlinksScreen.searchByDevEUIOrStatus')}
              value={filter?.keywords}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFilter(() => ({ ...filter, keywords: e?.target?.value, skip: 0 }));
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  onChangeFilter({ keywords: e?.target?.value });
                }, 300) as unknown as number;
              }}
            />
          </div>
          <div className='hidden sm:block w-full'>
            <Table
              columns={columns}
              dataSource={downLinks}
              hover
              onClickRow={(_, downLink) => {
                navigate(`/dashboard/downlinks/${downLink._id}`);
              }}
            />
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>
            {_renderOrganizationsForMobile()}
          </div>
        </div>
        <div className='mb-24'>
          <Pagination
            count={totalDownlinks}
            limit={limit}
            skip={skip}
            calc={calcPagination}
            onChange={pageParams => {
              onChangeFilter(pageParams);
            }}
            className='sm:mt-5'
          />
        </div>
      </>
    );
  };

  return (
    <div className='relative sm:bg-nell-dark-grey sm:pt-9 mb-2 rounded-[20px] overflow-hidden'>
      {_renderDownLinks()}
    </div>
  );
};
