import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, NavLink } from 'react-router-dom';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import { useStore } from 'services/context';
import { switchLanguage } from 'services/context/Auth/actions';
import { translate } from 'utils/helperFunctions';
import { ReactComponent as PersonIcon } from 'assets/images/Person.svg';

import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const TopbarMenu = () => {
  const [state, dispatch] = useStore();
  const [visible, setVisible] = useState<boolean>(false);
  const { userData } = state.authStore;
  const { i18n } = useTranslation();

  const onLogout = async () => {
    await AuthSessionServices.clearSession();
  };

  const getSwitchedToLang = () => (i18n.language.startsWith('en') ? 'Español' : 'English');

  const onChangeLanguage = () => {
    const lang = i18n.language.startsWith('en') ? 'es' : 'en';
    i18n.changeLanguage(lang);
    dispatch(switchLanguage(lang));
    setVisible(false);
  };

  const _renderConfirmChangeLanguage = () => (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <p className='text-white text-3xl font-black text-center'>
        {translate('settingsScreen.confirmChangeLanguage', { language: getSwitchedToLang() })}
      </p>
      <div className='flex justify-between items-center py-8 space-x-3 sm:space-x-0'>
        <div>
          <button
            className='rounded-btn-outline !border-white !text-white !h-13 !px-16'
            onClick={() => setVisible(false)}
          >
            {translate('common.cancel')}
          </button>
        </div>
        <div>
          <button className='tw-button !text-sm !h-13' onClick={() => onChangeLanguage()}>
            {translate('common.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {_renderConfirmChangeLanguage()}
      <Menu as='div' className='relative'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className={`md:w-52 flex items-center relative focus:outline-none outline-none space-x-2 p-3 before:lg:p-0 lg:before:shadow-none  ${
                  open
                    ? 'border-2 border-nell-blue border-b-0 rounded-[10px] rounded-b-none bg-nell-black invisible md:visible'
                    : 'hover:bg-nell-light-grey hover:rounded-[10px]'
                }`}
              >
                <div className='h-9 w-9 shrink-0 z-10 leading-10 flex items-center justify-center rounded-full font-black text-xl text-primary-dark  bg-nell-blue'>
                  <PersonIcon />
                </div>
                <p
                  className='text-[18px] truncate font-normal leading-6 text-white hidden md:block'
                  title={`${userData?.firstName} ${userData?.lastName}`}
                >
                  {userData?.firstName} {userData?.lastName}
                </p>
                <ChevronDownIcon
                  className={`${
                    open ? '-rotate-180' : ''
                  } text-white h-5 w-5 hidden md:block transition-all`}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='origin-center absolute z-50 right-0 top-4 md:top-auto w-52 rounded-t-[10px] md:rounded-t-none rounded-b-[10px] md:border-t-0 border-nell-blue border-2 py-1 bg-nell-black text-nell-white ring-0 ring-opacity-5 focus:outline-none'>
                <div className='md:hidden'>
                  <Menu.Button
                    className={`w-full flex items-center relative focus:outline-none outline-none space-x-2 p-3 before:lg:p-0 lg:before:shadow-none`}
                  >
                    <div className='h-9 w-9 shrink-0 z-10 leading-10 flex items-center justify-center rounded-full font-black text-xl text-primary-dark'>
                      <PersonIcon />
                    </div>
                    <p
                      className='text-[18px] truncate font-normal leading-6 text-white'
                      title={`${userData?.firstName} ${userData?.lastName}`}
                    >
                      {userData?.firstName} {userData?.lastName}
                    </p>
                    <XIcon className={`text-white h-7 w-7 transition-all`} />
                  </Menu.Button>
                </div>
                <Menu.Item>
                  <NavLink
                    to={'/dashboard/profile'}
                    className='hover:font-bold block px-4 py-2 text-lg capitalize cursor-pointer'
                  >
                    {translate(`common.profile`)}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={'#'}
                    className='hover:font-bold block px-4 py-2 text-lg capitalize cursor-pointer'
                    onClick={onLogout}
                  >
                    {translate(`common.logout`)}
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
