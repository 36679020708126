import axios from 'axios';
import { InviteMembership } from './Onboarding.types';

type GeneralParams = {
  [key: string]: any;
};

export const OnboardingServices = {
  fetchCreatorsUserList: async (): Promise<any> => {
    const endPoint = `onboarding/users/creator`;
    try {
      const creatorsUserList = await axios.get(endPoint);
      return creatorsUserList;
    } catch (error) {
      throw error;
    }
  },
  fetchCollectivesUserList: async (): Promise<any> => {
    const endPoint = `onboarding/users/collective`;
    try {
      const collectivesUserList = await axios.get(endPoint);
      return collectivesUserList;
    } catch (error) {
      throw error;
    }
  },
  fetchBrandsUserList: async (): Promise<any> => {
    const endPoint = `onboarding/users/brand`;
    try {
      const brandsUserList = await axios.get(endPoint);
      return brandsUserList;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyUsers: async (companyId: string, params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `onboarding/company/${companyId}/users`;
      const companyUsers = await axios.get(endPoint, { params });
      return companyUsers;
    } catch (error) {
      throw error;
    }
  },
  fetchAminsUserList: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `onboarding/admin/users/search`;
    try {
      const adminsUserList = await axios.get(endPoint, { params });
      return adminsUserList;
    } catch (error) {
      throw error;
    }
  },
  inviteMembership: async (body: InviteMembership): Promise<any> => {
    const endPoint = `onboarding/invite`;
    try {
      const invitedMembership = await axios.post(endPoint, body);
      return invitedMembership;
    } catch (error) {
      throw error;
    }
  },
  addUserAdmin: async (payload: any): Promise<any> => {
    const endPoint = `onboarding/admin/user`;
    try {
      const newUser = await axios.post(endPoint, payload);
      return newUser;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (id: string): Promise<any> => {
    const endPoint = `onboarding/admin/user/${id}`;
    try {
      const validatedUser = await axios.delete(endPoint);
      return validatedUser;
    } catch (error) {
      throw error;
    }
  },
  inviteUserByCompany: async (companyId: string, payload: any): Promise<any> => {
    const endPoint = `onboarding/company/${companyId}/user/invite`;
    try {
      const invitedUser = await axios.put(endPoint, payload);
      return invitedUser;
    } catch (error) {
      throw error;
    }
  },
  inviteUserByOrganization: async (companyId: string, payload: any): Promise<any> => {
    const endPoint = `onboarding/company/${companyId}/user`;
    try {
      const invitedUser = await axios.post(endPoint, payload);
      return invitedUser;
    } catch (error) {
      throw error;
    }
  },
};
