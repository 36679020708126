import React, { useEffect } from 'react';
import { useState } from 'react';
import { TiTick } from 'react-icons/ti';

export type Option = {
  code: string;
  name: string;
  description?: string;
  disabled?: boolean;
};
type Props = {
  options: Array<Option>;
  selectedPackages: any[];
  onChange: (value: any) => void;
  className?: string;
  circle?: boolean;
};

export const CustomCheckbox: React.FC<Props> = React.memo(
  ({ options, className, onChange, selectedPackages, circle }) => {
    const [checkedValue, setCheckedValue] = useState<any>(selectedPackages);
    const handleOnchange = (e: any) => {
      const { value } = e.target;
      if (!checkedValue.includes(value)) {
        setCheckedValue([...checkedValue, value]);
      } else {
        const removeUnchecked = checkedValue.filter((code: any) => code !== value);
        setCheckedValue(removeUnchecked);
      }
    };

    useEffect(() => {
      if (selectedPackages?.length > 0) {
        setCheckedValue(selectedPackages);
      } else {
        setCheckedValue([]);
      }
    }, [selectedPackages?.length]);

    useEffect(() => {
      onChange?.(checkedValue);
    }, [checkedValue]);

    return (
      <div className={className}>
        {options?.map(option => {
          return (
            <div key={option.code} className='flex items-start gap-7 p-2 '>
              <label
                htmlFor={option.code}
                className={`flex justify-center items-center   ${
                  option?.disabled === true ? 'cursor-not-allowed' : 'cursor-pointer'
                }  border-2 border-nell-blue shrink-0 ${
                  circle ? 'w-5 h-5 rounded-full' : 'w-6 h-6 rounded-md'
                } `}
              >
                {checkedValue.includes(option.code) && (
                  <>
                    {circle ? (
                      <span className='w-3 h-3 rounded-full bg-nell-blue'></span>
                    ) : (
                      <TiTick className='w-6 h-6 text-nell-white rounded-md bg-nell-blue' />
                    )}
                  </>
                )}
                <input
                  type='checkbox'
                  id={option.code}
                  value={option.code}
                  disabled={option?.disabled || false}
                  className='hidden'
                  onChange={handleOnchange}
                />
              </label>

              <label
                htmlFor={option.code}
                className={`${option?.disabled === true ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                <div className='font-bold'>{option.name}</div>
                <p className='font-medium max-w-sm'>{option?.description}</p>
              </label>
            </div>
          );
        })}
      </div>
    );
  }
);
