import { Loader } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TagServices } from 'services/apis/Tag';
import { getStatusCardClass, translate } from 'utils/helperFunctions';

export const TagDetails = () => {
  const { devEUI } = useParams();
  const [tagDetails, setTagDetails] = useState<{ [ky: string]: any }>({});
  const [loader, setLoader] = useState<boolean>(true);
  const fetchTagDetails = async () => {
    if (devEUI) {
      try {
        const tag = await TagServices.fetchTagDetails(devEUI);
        setTagDetails(tag);
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    fetchTagDetails();
  }, []);

  function formatDate(timestamp: any) {
    const date = new Date(timestamp);
    const stringDate = date.toString();
    const splitted = stringDate.split(' ');
    return splitted.slice(0, 5).join(' ');
  }

  const _renderTagDetails = () => {
    return (
      <div>
        <div className='flex items-start sm:items-center justify-start flex-col sm:flex-wrap sm:flex-row gap-4 px-4'>
          <div>
            <span className='font-prototype text-2xl'>{translate('factoryTags.tagID')} :</span>{' '}
            <span className='text-nell-blue font-prototype text-2xl'>{tagDetails?._id}</span>
          </div>
          <div className='sm:grow'>
            <div
              className={`${getStatusCardClass(
                tagDetails?.status
              )} w-24 py-2 rounded-2xl text-center text-sm text-nell-black font-heavyGaret capitalize p-1`}
            >
              {tagDetails?.status}
            </div>
          </div>
          {tagDetails?.status !== 'new' && (
            <Link
              to={`/dashboard/tags/${tagDetails?._id}`}
              className='flex items-center gap-1 text-nell-white pr-9 text-[20px]'
            >
              <span className='underline'>{translate('factoryTags.tagDashboard')}</span>
              <BsArrowRightShort className='text-2xl' />
            </Link>
          )}
        </div>
        <hr className='border-t-1 border-nell-light-grey my-5' />
        <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 break-all whitespace-normal'>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.devEUI2')}</div>
            <div className=''>{tagDetails?.devEUI || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.appKey')}</div>
            <div className=''>{tagDetails?.appKey || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.appEUI2')}</div>
            <div className=''>{tagDetails?.appEUI || '-'}</div>
          </div>
        </div>
        <hr className='border-t-1 border-nell-light-grey my-5' />
        <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 '>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.driver')}</div>
            <div className=''>{tagDetails?.driver || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret break-words'>
              {translate('factoryTags.networkConnection')}
            </div>
            <div className=''>{tagDetails?.networkConnectionInfo?.name || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.network')}</div>
            <div className='capitalize'>{tagDetails?.network || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.serialNumber')}</div>
            <div className=''>{tagDetails?.serialnumber || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.regions')}</div>
            <div className=''>{tagDetails?.region || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.batteryType')}</div>
            <div className=''>{tagDetails?.batteryType || '-'}</div>
          </div>
        </div>
        <hr className='border-t-1 text-[15px] border-nell-light-grey my-5 ' />
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4  ${
            tagDetails.status === 'active' ? '' : 'text-nell-light-grey'
          }`}
        >
          <div className='space-y-2'>
            <div className={`font-heavyGaret`}>{translate('factoryTags.timeBooked')}</div>
            <div className=''>
              {tagDetails?.time?.bookedAt ? formatDate(tagDetails?.time?.bookedAt) : '-'}
            </div>
          </div>
          <div className='space-y-2 lg:col-span-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.timeActivated')}</div>
            <div className=''>
              {tagDetails?.time?.activatedAt ? formatDate(tagDetails?.time?.activatedAt) : '-'}
            </div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.consumerFirstName')}</div>
            <div className=''>{tagDetails?.consumerInfo?.firstName || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.consumerLastName')}</div>
            <div className=''>{tagDetails.consumerInfo?.lastName || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.consumerEmail')}</div>
            <div className=''>{tagDetails?.consumerInfo?.email || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.ownerFirstName')}</div>
            <div className=''>{tagDetails?.ownerInfo?.firstName || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.ownerLastName')}</div>
            <div className=''>{tagDetails?.ownerInfo?.lastName || '-'}</div>
          </div>
          <div className='space-y-2'>
            <div className='font-heavyGaret'>{translate('factoryTags.ownerEmail')}</div>
            <div className='break-all'>{tagDetails?.ownerInfo?.email || '-'}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='text-nell-white relative sm:bg-nell-dark-grey py-9 rounded-[20px] overflow-hidden mb-10'>
      {loader ? (
        <div
          className='flex justify-center items-center xh-screen w-full m-auto'
          style={{ height: `calc(100vh - 170px)` }}
        >
          <Loader />
        </div>
      ) : (
        _renderTagDetails()
      )}
    </div>
  );
};
