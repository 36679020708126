import React, { useState, useEffect } from 'react';

type Props = {
  timeStamp: number;
  format?: 'YY:MM:HH' | 'DD/MM/YY - HH:mm';
};

export const CustomDateTimeDisplay = ({ timeStamp, format = 'YY:MM:HH' }: Props) => {
  const date = new Date(timeStamp * 1000);
  const [time, setTime] = useState({
    hours: date.getHours(),
    minutes: date.getMinutes(),
  });
  const year = date.getFullYear().toString().substr(-2);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => {
        let newMinutes = prevTime.minutes + 1;
        let newHours = prevTime.hours;

        if (newMinutes === 60) {
          newMinutes = 0;
          newHours = (prevTime.hours + 1) % 24;
        }

        return { hours: newHours, minutes: newMinutes };
      });
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = time.minutes.toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  const Option1FormattedDate = `${day}/${month}/${year}`;
  const Option2FormattedDate = `${year}/${month}/${hours}`;
  const formattedDateTime =
    format === 'YY:MM:HH'
      ? `${Option2FormattedDate}`
      : `${Option1FormattedDate} - ${formattedTime}`;

  return <div>{formattedDateTime}</div>;
};
