import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';

type SelectProps = {
  options: any[] | [];
  onChange: (value: any) => void;
  value: string;
};

export const CustomRadio: React.FC<SelectProps> = React.memo(({ options, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<any>({
    value: '',
    label: '',
  });

  useEffect(() => {
    if (value != undefined) {
      setSelectedOption(options.find(option => option.value === value));
    }
  }, [value]);

  return (
    <RadioGroup
      value={selectedOption?.value}
      onChange={selected => {
        const valueAfterSelect = options?.find(option => option?.value === selected);
        setSelectedOption(valueAfterSelect);
        onChange(valueAfterSelect);
      }}
    >
      <RadioGroup.Label className='sr-only'>Accounts</RadioGroup.Label>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-3'>
        {options &&
          options.map((option: any, index: number) => {
            return (
              <RadioGroup.Option
                key={index}
                value={option.value}
                className={() => 'flex items-center cursor-pointer'}
              >
                {({ checked }) => (
                  <div className='flex items-center'>
                    <RadioGroup.Description as='div' className='text-sm'>
                      <div
                        className={`${
                          checked ? 'relative' : ''
                        } bg-nell-light-grey  rounded-full h-5 w-5`}
                      >
                        {checked ? (
                          <div className='h-3 w-3 bg-nell-blue rounded-full absolute inset-1 ' />
                        ) : null}
                      </div>
                    </RadioGroup.Description>
                    <div>
                      <div>
                        <RadioGroup.Label
                          as='p'
                          className='font-bold text-sm text-center text-primary-leaf ml-5'
                        >
                          {option.label}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            );
          })}
      </div>
    </RadioGroup>
  );
});
