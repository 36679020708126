import { Loader, Tabs } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { OrganizationServices } from 'services/apis/Organization';
import { translate } from 'utils/helperFunctions';
import { OrganizationDetailsUsers } from './OrganizationDetailsUsers';
import { OrganizationDetailsRoles } from './OrganizationDetailsRoles';
import { OrganizationChangeStatus } from './OrganizationChangeStatus';
import DetailsLessMore from 'components/shared/DetailsLessMore';

export const OrganizationDetailsSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'users',
  });
  const [isStatusVisible, setIsStatusVisible] = useState(false);
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const tabParam = searchParams.get('tab');
  const [companyProfile, setCompanyProfile] = useState<any>({
    name: '',
    description: '',
    status: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: '',
    },
    profile: {
      info: {
        operatingName: '',
        website: '',
      },
      contactInfo: {
        salutation: '',
        firstName: '',
        lastName: '',
        title: '',
        phone: '',
        email: '',
        preferredContact: '',
        preferredLanguage: '',
      },
    },
  });

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <OrganizationDetailsUsers />;
      case '2':
        return <OrganizationDetailsRoles />;
      default:
        return null;
    }
  };

  const fetchOrganizationById = async () => {
    try {
      if (id) {
        const company = await OrganizationServices.fetchCompanyById(id);
        setCompanyProfile(company);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      throw err;
    }
  };

  useEffect(() => {
    fetchOrganizationById();
  }, []);

  useEffect(() => {
    if (!tabParam || tabParam === 'users') {
      setActiveKeyTab('1');
    } else {
      setActiveKeyTab('2');
    }
  }, [tabParam]);

  const addressObj = companyProfile?.address;
  const addressValues =
    companyProfile?.address &&
    Object.keys(addressObj)
      .filter(key => key !== '' && key.includes('address'))
      .map(key => companyProfile?.address[key])
      .filter(address => address !== '')
      .join(', ');

  const _renderOrganizationDetails = () => {
    return (
      <>
        {isStatusVisible && (
          <OrganizationChangeStatus
            isStatusVisible={isStatusVisible}
            setIsStatusVisible={setIsStatusVisible}
            companyProfile={companyProfile}
            setCompanyProfile={setCompanyProfile}
          />
        )}
        <div>
          <div className='flex items-center justify-end flex-wrap gap-4 px-4'>
            <div className='justify-self-start grow'>
              <span className='xtext-nell-blue font-prototype text-2xl'>
                {companyProfile?.name}
              </span>
            </div>
            <button
              onClick={() => navigate(`/dashboard/organizations/create/${id}`)}
              className='tw-button !border-2 !w-[130px] sm:!w-[150px] h-14 !rounded-lg '
            >
              {translate('common.edit')}
            </button>
            <button
              onClick={() => {
                setIsStatusVisible(!isStatusVisible);
              }}
              className={`tw-button !border-2 !w-[130px] sm:!w-[150px] h-14 !rounded-lg ${
                companyProfile?.status === 'active' ? '!bg-nell-green' : '!bg-nell-red'
              }`}
            >
              {companyProfile?.status === 'active'
                ? translate('common.active')
                : translate('common.inactive')}
            </button>
            <div className='w-full flex gap-4 justify-end pr-10'></div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {translate('organizationsScreen.companyInfo')} :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 break-all whitespace-normal'>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>
                {translate('organizationsScreen.operatingName')}
              </div>
              <div className=''>{companyProfile?.profile?.info?.operatingName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.website')}</div>
              <div className=''>{companyProfile?.profile?.info?.website || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.address')}</div>
              <div className=''>{addressValues || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.city')}</div>
              <div className=''>{companyProfile?.address?.city || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.postalCode')}</div>
              <div className=''>{companyProfile?.address?.postalCode || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.region')}</div>
              <div className=''>{companyProfile?.address?.region || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.country')}</div>
              <div className=''>{companyProfile?.address?.country || '-'}</div>
            </div>
            <div className='space-y-4'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.description')}</div>
              <div>
                <div>
                  <DetailsLessMore description={companyProfile?.description} />
                </div>
              </div>
            </div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {translate('organizationsScreen.contactInfo')} :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 '>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.Salutation')}</div>
              <div className=''>{companyProfile?.profile?.contactInfo?.salutation || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret break-words'>
                {translate('organizationsScreen.firstName')}{' '}
              </div>
              <div className=''>{companyProfile?.profile?.contactInfo?.firstName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.lastName')}</div>
              <div className='capitalize'>
                {companyProfile?.profile?.contactInfo?.lastName || '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.title')}</div>
              <div className=''>{companyProfile?.profile?.contactInfo?.title || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.phoneNumber')}</div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.phone
                  ? +companyProfile?.profile?.contactInfo?.phone
                  : '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('organizationsScreen.email')}</div>
              <div className='break-all'>{companyProfile?.profile?.contactInfo?.email || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>
                {translate('organizationsScreen.prefferedContact')}
              </div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.preferredContact || '-'}
              </div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>
                {translate('organizationsScreen.prefferedLanguage')}
              </div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.preferredLanguage || '-'}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const _renderUserAndRoles = () => {
    return (
      <div className={`relative sm:bg-nell-dark-grey sm:pt-8 rounded-[20px] mb-4`}>
        <Tabs
          activeKey={activeKeyTab}
          onChange={key => {
            setActiveKeyTab(key);
            setSearchParams({ tab: key === '1' ? 'users' : 'groups' });
          }}
          tabPanes={[
            { name: translate('settingsScreen.users'), key: '1' },
            { name: translate('settingsScreen.roles'), key: '2' },
          ]}
        />
        {_renderTabs()}
      </div>
    );
  };

  return (
    <>
      <div className='text-nell-white relative sm:bg-nell-dark-grey py-9 rounded-[20px] overflow-hidden mb-10'>
        {loader ? (
          <div
            className='flex justify-center items-center xh-screen w-full m-auto'
            style={{ height: `calc(100vh - 170px)` }}
          >
            <Loader />
          </div>
        ) : (
          _renderOrganizationDetails()
        )}
      </div>
      {_renderUserAndRoles()}
    </>
  );
};
