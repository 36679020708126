import React, { useEffect, useState } from 'react';
import { CustomCombobox, Modal, Pagination, Select, Spiner, Table } from 'components/shared';
import { calcPagination, translate } from 'utils/helperFunctions';
import { SearchIcon } from '@heroicons/react/outline';
import { OnboardingServices } from 'services/apis/Onboarding';
import { toast } from 'react-toastify';
import { UracServices } from 'services/apis/Urac';
import { useStore } from 'services/context';
import { BsPen, BsPlus, BsThreeDotsVertical, BsTrash } from 'react-icons/bs';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { Menu } from '@headlessui/react';
import UsersFilter from './UsersFilter';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../components/componentStyle/Styles.css';

let debounce: any = null;
export const Users = () => {
  const [state] = useStore();
  const {
    authStore: { userData },
  } = state;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisibleNewUser, setIsVisibleNewUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<{ [key: string]: any }>({});
  const [users, setUsers] = useState<any[]>([]);
  const [adminGroups, setAdminGroups] = useState<any[]>([]);
  const [totalResult, setTotalResult] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    keywords: '',
  });
  const [isEditableUser, setIsEditableUser] = useState<boolean>(false);
  const [isVisibleDeleteUser, setIsVisibleDeleteUser] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<any>([]);
  const [phoneCode, setPhoneCode] = useState<string>('1');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [nameSort, setNameSort] = useState<'asc' | 'desc' | undefined>(undefined);
  const [emailSort, setEmailSort] = useState<'asc' | 'desc' | undefined>(undefined);

  const getSelectedUserTenant = (user: any): any => {
    const tenantId = adminGroups?.[0]?.tenant?.id;
    const adminRoles = user?.config?.allowedTenants?.find((ele: any) => {
      return ele?.tenant?.id === tenantId;
    });
    return adminRoles?.groups || [];
  };

  const getAdminUsers = async (params = calcPagination()) => {
    OnboardingServices.fetchAminsUserList(params)
      .then(data => {
        setUsers(
          data.items.filter((user: any) => {
            return user._id !== userData._id;
          })
        );
        setTotalResult(data.count);
        setCurrentPage(params.skip / params.limit + 1);
        return;
      })
      .catch(() => {});
  };

  const areArraysEqualInEditRole = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((word: any) => arr2.includes(word));
  };

  useEffect(() => {
    getAdminUsers();
    UracServices.fetchAdminGroups()
      .then(data => {
        setAdminGroups(data);
        return;
      })
      .catch(() => {});
  }, []);

  const onChangeFilter = (newFilters = {}) => {
    setFilter({ ...filter, ...newFilters });
    getAdminUsers({ ...calcPagination(), ...filter, ...newFilters });
  };

  const onDeleteUser = async () => {
    setIsLoading(true);
    try {
      await OnboardingServices.deleteUser(selectedUser._id);
      setUsers(users.filter(user => user._id !== selectedUser._id));
      toast.success(translate('settingsScreen.userDeleted'));
      getAdminUsers();
      setIsLoading(false);
      setIsVisibleDeleteUser(false);
      setSelectedUser({});
    } catch (error: any) {
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  const onAddUser = async () => {
    try {
      setIsLoading(true);
      selectedUser.email = selectedUser?.email?.toLowerCase();
      const profile = userData.profile || {};
      await OnboardingServices.addUserAdmin({
        ...selectedUser,
        phone: `${phoneCode}${phoneNumber}`,
        profile: { ...profile, phoneNumber: phoneNumber, countryCode: phoneCode },
      });
      toast.success(translate('settingsScreen.userAddedSuccessfully'));
      getAdminUsers();
      setIsLoading(false);
      setSelectedUser({});
      setPhoneCode('');
      setPhoneNumber('');
      setIsVisibleNewUser(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  const onChangeRole = async () => {
    setIsLoading(true);
    if (selectedRole) {
      const data = await UracServices.updateAdminGroups({
        groups: selectedRole,
        user: { id: selectedUser?._id },
      });
      setIsLoading(false);

      if (data) {
        setIsEditableUser(false);
        toast.success(translate('settingsScreen.userRoleUpdatedSuccessfully'));
        getAdminUsers();
      }
    }
  };

  const _renderEditUserConfirmation = () => {
    return (
      <Modal
        visible={isEditableUser}
        onClose={() => {
          setIsEditableUser(false);
          setSelectedUser({});
          setSelectedRole([]);
        }}
        modalTitle={translate('settingsScreen.editUser')}
      >
        <div className='flex flex-col gap-8 px-4 sm:px-10'>
          <div className='flex flex-col gap-2'>
            <p className='font-black'>{translate('settingsScreen.firstName')}</p>
            <p>{selectedUser?.firstName || '-'}</p>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='font-black'>{translate('settingsScreen.lastName')}</p>
            <p>{selectedUser?.lastName || '-'}</p>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='font-black'>{translate('settingsScreen.email')}</p>
            <p>{selectedUser?.email || '-'}</p>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='font-black'>{translate('settingsScreen.phoneNumber')}</p>
            <p>{`+${selectedUser?.phone}` || '-'}</p>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-black text-nell-blue text-sm'>{translate('common.role')}</p>
            <CustomCombobox
              options={[...adminGroups]?.map((group: any) => ({
                value: group.code,
                label: group.name,
              }))}
              selectedValues={getSelectedUserTenant(selectedUser)}
              placeholder={translate('settingsScreen.selectRole')}
              onChange={setSelectedRole}
            />
          </div>
          <div className='w-full'>
            <button
              type='button'
              onClick={onChangeRole}
              disabled={
                areArraysEqualInEditRole(getSelectedUserTenant(selectedUser), selectedRole) ||
                isLoading ||
                selectedRole.length === 0
              }
              className='tw-button !w-full'
            >
              {isLoading && <Spiner className='ml-52' />}
              {translate('settingsScreen.saveChanges')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const _renderDeleteUserConfirmation = () => (
    <Modal
      visible={isVisibleDeleteUser}
      modalTitle={translate('settingsScreen.deleteUser')}
      onClose={() => {
        setSelectedUser({});
        setIsVisibleDeleteUser(false);
      }}
    >
      <div className='px-4'>
        <p className='text-base text-left'>
          {translate('settingsScreen.areYouSureYouWantToDeleteUser')}
        </p>
        <p className='font-heavyGaret text-base text-left font-bold mt-3 capitalize'>
          {selectedUser?.firstName}
        </p>
        <div className='flex gap-x-3 items-center justify-end mt-6'>
          <div className=''>
            <button
              className='tw-button !bg-transparent !h-8 !w-[85px] sm:!w-40 sm:!h-14 !border-nell-blue !text-nell-blue'
              onClick={() => {
                setIsVisibleDeleteUser(false);
                setSelectedUser({});
              }}
            >
              <span className='hidden md:block'> {translate('settingsScreen.noKeep')}</span>
              <span className='md:hidden w-20'> {translate('settingsScreen.keep')}</span>
            </button>
          </div>
          <div className='flex'>
            <button
              className={`tw-button !w-[85px] !h-8 sm:!w-40 sm:!h-14 !bg-nell-red `}
              disabled={isLoading}
              onClick={() => onDeleteUser()}
            >
              {isLoading && <Spiner className='ml-2 !text-primary-mint' />}
              <span className='hidden md:block'>{translate('settingsScreen.yesDelete')}</span>
              <span className='md:hidden w-20'>{translate('settingsScreen.delete')}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const _renderModalAddUser = () => {
    const inputFields = [
      {
        key: 'firstName',
        label: translate('settingsScreen.firstName'),
        placeholder: translate('settingsScreen.enterFirstName'),
        type: 'text',
      },
      {
        key: 'lastName',
        label: translate('settingsScreen.lastName'),
        placeholder: translate('settingsScreen.enterLastName'),
        type: 'text',
      },
      {
        key: 'phone',
        label: translate('settingsScreen.phoneNumber'),
        placeholder: translate('settingsScreen.enterPhoneNumber'),
        type: 'number',
      },
      {
        key: 'email',
        label: translate('settingsScreen.email'),
        placeholder: translate('settingsScreen.enterEmail'),
        type: 'email',
      },
      {
        key: 'password',
        label: translate('settingsScreen.password'),
        placeholder: translate('common.enterPassword'),
        type: 'password',
      },
      {
        key: 'groups',
        label: translate('common.role'),
        placeholder: translate('settingsScreen.selectRole'),
        type: 'text',
      },
    ];

    return (
      <Modal
        visible={isVisibleNewUser}
        onClose={() => {
          setSelectedUser({});
          setIsVisibleNewUser(false);
        }}
        modalTitle={translate('settingsScreen.addNewUser')}
      >
        <div className='text-white pb-7'>
          <div className='flex flex-col'>
            {inputFields.map(({ key, label, placeholder, type }) => {
              if (key === 'groups') {
                return (
                  <div key={key} className='flex flex-col gap-2  px-4 md:px-10'>
                    <label className='text-nell-blue text-sm'>{label}</label>
                    <CustomCombobox
                      options={[...adminGroups]?.map((group: any) => ({
                        value: group.code,
                        label: group.name,
                      }))}
                      selectedValues={Array.isArray(selectedUser[key]) ? selectedUser[key] : ''}
                      placeholder={placeholder}
                      onChange={(value: any) => setSelectedUser({ ...selectedUser, [key]: value })}
                    />
                  </div>
                );
              } else if (key === 'password') {
                return (
                  <div key={key}>
                    <div className='mb-5 flex flex-col gap-2  px-4 md:px-10'>
                      <p className='text-nell-blue text-sm capitalize'>{label}</p>
                      <input
                        type={type}
                        name={key}
                        value={selectedUser[key] || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSelectedUser({ ...selectedUser, [key]: e.target.value })
                        }
                        className='tw-input placeholder:!capitalize'
                        placeholder={placeholder}
                        autoComplete='off'
                      />
                    </div>
                    <div className='px-4 md:px-10 '>
                      <div className='flex  rounded-lg bg-nell-mid-grey text-sm py-2 mt-2 mb-5 '>
                        <div className='w-16 flex items-center justify-center'>
                          <BsFillExclamationCircleFill className='w-15 !text-xl text-nell-light-grey' />
                        </div>
                        <div className='text-nell-light-grey leading-6  '>
                          {translate(
                            'settingsScreen.ifPasswordWasNotSpecifiedThePasswordWillBePassword'
                          )}
                          <span className='!text-nell-white'>
                            {' '}
                            {translate('settingsScreen.password')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else if (key === 'phone') {
                return (
                  <div key={key}>
                    <div className='relative flex flex-col gap-2  px-4 md:px-10'>
                      <p className='text-nell-blue text-sm capitalize'>{label}</p>
                      <div className='flex gap-2'>
                        <span className='absolute top-[32px] sm:top-[34px] left-6 md:left-12 z-10'>
                          <PhoneInput
                            country={'us'}
                            buttonStyle={{
                              background: '#171717',
                              border: 'none',
                              bottom: '2px',
                              top: '2px',
                              left: '2px',
                            }}
                            containerStyle={{
                              width: `${innerWidth < 640 ? '88px' : '88px'}`,
                              margin: 'auto',
                            }}
                            dropdownStyle={{ color: 'white', background: '#161919' }}
                            value={phoneCode}
                            onChange={(phone: any) => setPhoneCode(phone)}
                            placeholder={''}
                            inputProps={{
                              className:
                                'block w-full border-0 bg-transparent focus:ring-0 sm:text-sm text-right !pl-10 focus::!bg-black',
                            }}
                          />
                        </span>
                        <input
                          type='text'
                          name={key}
                          id='phone'
                          value={phoneNumber}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                              setPhoneNumber(e.target.value);
                            }
                          }}
                          className={`tw-input !pl-28 md:!pl-32`}
                          style={{ textAlign: 'left' }}
                          placeholder={translate('profileScreen.phoneNumber')}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                    <hr className='border-1 my-8 border-nell-light-grey w-full' />
                  </div>
                );
              } else {
                return (
                  <div key={key} className='mb-5 flex flex-col gap-2  px-4 md:px-10'>
                    <p className='text-nell-blue text-sm capitalize'>{label}</p>
                    <input
                      type={type}
                      name={key}
                      value={selectedUser[key] || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSelectedUser({ ...selectedUser, [key]: e.target.value })
                      }
                      className='tw-input placeholder:!capitalize'
                      placeholder={placeholder}
                      autoComplete='off'
                    />
                  </div>
                );
              }
            })}
            <div className='self-center !w-full  px-4 md:px-10'>
              <button
                type='button'
                className='tw-button mt-8 !w-full'
                disabled={
                  isLoading ||
                  selectedUser?.firstName === '' ||
                  selectedUser?.lastName === '' ||
                  selectedUser?.email === '' ||
                  selectedUser?.phoneNumber === '' ||
                  selectedUser?.groups?.length === 0
                }
                onClick={onAddUser}
              >
                {isLoading && <Spiner className='ml-36 sm:ml-52' />}
                {translate('settingsScreen.addUser')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderUsersForMobile = () => {
    return (
      <div>
        <UsersFilter
          onChange={onChangeFilter}
          filter={filter}
          setNameSort={setNameSort}
          setEmailSort={setEmailSort}
          adminGroups={adminGroups}
        />
        <div className={`flex text-nell-white justify-between items-end pb-4 w-72 text-sm `}></div>
        <div className='flex flex-col gap-4 sm:hidden -4'>
          {users.map((user, index) => (
            <div key={index}>
              <div className='bg-nell-mid-grey hover:bg-nell-light-grey  text-nell-white rounded-[8px] z-10'>
                <div className='relative z-10'>
                  <div className='w-full absolute top-20 h-[1px] bg-nell-light-grey -z-10'></div>
                  <div className='flex justify-between pt-[10px] px-2'>
                    <div className='flex flex-col w-full'>
                      <div className='flex flex-col h-[70px] w-full'>
                        <h2 className='font-heavyGaret capitalize'>
                          {user.firstName} {user.lastName}
                        </h2>
                        <p className='ellipsis pt-2'>{user.email}</p>
                      </div>

                      <div className='w-full flex gap-3 capitalize h-9 items-center text-sm'>
                        {getSelectedUserTenant(user)?.map((role: string) => (
                          <p key={role}>{role}</p>
                        ))}
                      </div>
                    </div>
                    <Menu as='div' className='relative h-5 '>
                      <Menu.Button className=''>
                        <span className='sr-only'>Open options</span>
                        <BsThreeDotsVertical className='text-2xl h-6 cursor-pointer' />
                      </Menu.Button>
                      <Menu.Items
                        className={`absolute flex gap-3 p-2 right-0 top-0 z-10 rounded-md bg-nell-grey-secondary`}
                      >
                        <Menu.Item>
                          {() => (
                            <button
                              onClick={() => {
                                setSelectedUser(user);
                                setIsVisibleDeleteUser(true);
                              }}
                              className='bg-nell-red rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110'
                            >
                              <BsTrash />
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <button
                              onClick={() => {
                                setIsEditableUser(true);
                                setSelectedUser(user);
                              }}
                              className='bg-nell-blue rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110'
                            >
                              <BsPen />
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: (
        <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
          <span>{translate('common.name')}</span>
          <span className='flex flex-col'>
            <MdKeyboardArrowUp
              onClick={() => {
                setEmailSort(undefined);
                delete filter['sortBy[0][field]'];
                delete filter['sortBy[0][type]'];
                delete filter['sortBy[1][field]'];
                delete filter['sortBy[1][type]'];
                if (nameSort !== 'desc') {
                  setNameSort('desc');
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                    'sortBy[0][field]': 'firstName',
                    'sortBy[0][type]': 'desc',
                    'sortBy[1][field]': 'lastName',
                    'sortBy[1][type]': 'desc',
                  });
                } else {
                  setNameSort(undefined);
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                  });
                }
              }}
              className={`text-2xl cursor-pointer ${nameSort === 'desc' ? 'text-nell-blue' : ''}`}
            />
            <MdKeyboardArrowUp
              onClick={() => {
                setEmailSort(undefined);
                delete filter['sortBy[0][field]'];
                delete filter['sortBy[0][type]'];
                delete filter['sortBy[1][field]'];
                delete filter['sortBy[1][type]'];
                if (nameSort !== 'asc') {
                  setNameSort('asc');
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                    'sortBy[0][field]': 'firstName',
                    'sortBy[0][type]': 'asc',
                    'sortBy[1][field]': 'lastName',
                    'sortBy[1][type]': 'asc',
                  });
                } else {
                  setNameSort(undefined);
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                  });
                }
              }}
              className={`text-2xl rotate-180 -mt-3 cursor-pointer ${
                nameSort === 'asc' ? 'text-nell-blue' : ''
              } `}
            />
          </span>
        </div>
      ),
      dataIndex: 'name',
      render: (_: any, item: any) => `${item.firstName || ''} ${item.lastName || ''}`,
    },
    {
      title: (
        <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
          <span>{translate('common.email')}</span>
          <span className='flex flex-col'>
            <MdKeyboardArrowUp
              onClick={() => {
                setNameSort(undefined);
                delete filter['sortBy[0][field]'];
                delete filter['sortBy[0][type]'];
                delete filter['sortBy[1][field]'];
                delete filter['sortBy[1][type]'];
                if (emailSort !== 'desc') {
                  setEmailSort('desc');
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                    'sortBy[0][field]': 'email',
                    'sortBy[0][type]': 'desc',
                  });
                } else {
                  setEmailSort(undefined);
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                  });
                }
              }}
              className={`text-2xl cursor-pointer ${emailSort === 'desc' ? 'text-nell-blue' : ''}`}
            />
            <MdKeyboardArrowUp
              onClick={() => {
                setNameSort(undefined);
                delete filter['sortBy[0][field]'];
                delete filter['sortBy[0][type]'];
                delete filter['sortBy[1][field]'];
                delete filter['sortBy[1][type]'];
                if (emailSort !== 'asc') {
                  setEmailSort('asc');
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                    'sortBy[0][field]': 'email',
                    'sortBy[0][type]': 'asc',
                  });
                } else {
                  setEmailSort(undefined);
                  onChangeFilter({
                    ...filter,
                    skip: 0,
                  });
                }
              }}
              className={`text-2xl rotate-180 -mt-3 cursor-pointer ${
                emailSort === 'asc' ? 'text-nell-blue' : ''
              } `}
            />
          </span>
        </div>
      ),
      dataIndex: 'email',
    },
    {
      title: (
        <div className='w-[120px]'>
          <Select
            options={
              (adminGroups && adminGroups.map(grp => ({ value: grp.code, label: grp.name }))) || []
            }
            onChange={e => {
              if (e?.value === 'all') {
                delete filter.status;
                onChangeFilter({ ...filter, skip: 0 });
              } else {
                onChangeFilter({ ...filter, skip: 0, role: e?.value });
              }
            }}
            placeholder={'Role'}
            value={'Role'}
            preValue=''
            className='bg-nell-dark-grey'
          />
        </div>
      ),
      dataIndex: `type`,
      render: (_: any, item: any) => {
        const adminRoles = getSelectedUserTenant(item);
        const adminRolesGroups = adminRoles?.join(' - ');
        return adminRolesGroups;
      },
    },
    {
      title: translate('common.actions'),
      dataIndex: '',
      align: 'center',
      theadClass: 'w-[150px]',
      render: (_: any, item: any) => {
        return (
          <div className='flex justify-center align-middle gap-4 text-base items-center'>
            <button
              onClick={() => {
                setSelectedUser(item);
                setIsVisibleDeleteUser(true);
              }}
              className='bg-nell-red rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110'
            >
              <BsTrash />
            </button>
            <button
              onClick={() => {
                setIsEditableUser(true);
                setSelectedUser(item);
              }}
              className='bg-nell-blue rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110'
            >
              <BsPen />
            </button>
          </div>
        );
      },
    },
  ];

  const { limit, skip } = calcPagination(currentPage);

  return (
    <>
      <div className='mt-7 pb-0 sm:pb-0 mb-10'>
        {_renderDeleteUserConfirmation()}
        {_renderEditUserConfirmation()}
        {_renderModalAddUser()}
        <div className='fixed bottom-4 sm:bottom-auto right-6 sm:absolute sm:top-6  z-20 '>
          <button
            type='button'
            className='tw-button !border-0 sm:!border-2 w-14 sm:w-fit h-14 sm:!pr-4 !p-0 !rounded-lg '
            onClick={() => {
              setIsVisibleNewUser(true);
            }}
          >
            <BsPlus className='text-5xl ' />
            <span className='hidden sm:block'>{translate('settingsScreen.addNewUser')}</span>
          </button>
        </div>
        <div className='relative px-4'>
          <div className='absolute inset-y-0 left-4 pl-3 flex items-center pointer-events-none'>
            <SearchIcon className='h-5 w-5 text-nell-light-grey' aria-hidden='true' />
          </div>
          <input
            type='search'
            name='search'
            className='tw-input w-full !rounded-[10px] !pl-10'
            placeholder={translate('settingsScreen.searchByNameEmailOrRole')}
            autoComplete='off'
            value={filter?.keywords}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilter(() => ({ ...filter, keywords: e?.target?.value, skip: 0 }));
              clearTimeout(debounce);
              debounce = setTimeout(() => {
                onChangeFilter({ keywords: e?.target?.value });
              }, 300);
            }}
          />
        </div>
        <div className='hidden sm:block w-full'>
          <Table
            columns={columns}
            dataSource={users}
            hover
            onClickRow={(_, item) => {
              setSelectedUser(item);
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderUsersForMobile()}</div>
      <div className='sm:block w-full mb-24 sm:mb-0'>
        <Pagination
          count={totalResult}
          limit={limit}
          skip={skip}
          onChange={pageParams => {
            getAdminUsers(pageParams);
          }}
          className='mt-5'
        />
      </div>
    </>
  );
};
