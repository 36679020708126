import React, { useEffect, useState } from 'react';
import { calcPagination, getStatusClass, translate } from 'utils/helperFunctions';
import { AlignTypes, ComingSoon, Modal, Pagination, Spiner, Table, Tabs } from 'components/shared';

import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommerceServices } from 'services/apis/Commerce';
import OrdersFilters from './OrdersFilters';
type TabEnums = 'producer' | 'packer';

export const Orders = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isVisibleCompany, setIsVisibleCompany] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState<TabEnums>('producer');

  const [company, setCompany] = useState({
    name: '',
    description: '',
  });
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [countCompanies, setCountCompanies] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchOrdersList = (params = calcPagination()) => {
    CommerceServices.fetchOrders(params)
      .then((data: any) => {
        setCompanies(data.items);
        setCountCompanies(data.count);
        setCurrentPage(params.skip / params.limit + 1);
        return;
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchOrdersList({ ...filter, ...calcPagination() });
  }, [activeTab]);

  // const resetAddCompany = () => {
  //   setCompany({
  //     name: '',
  //     description: '',
  //   });
  // };

  const onAddCompany = () => {
    setIsLoading(true);
    // OrganizationServices.addCompany(activeTab, company)
    //   .then(() => {
    //     toast.success(translate('companiesScreen.companyAddSuccessfully'));
    //     setIsVisibleCompany(false);
    //     setIsLoading(false);
    //     resetAddCompany();
    //     setFilter({});
    //     fetchOrdersList();
    //     return;
    //   })
    //   .catch(error => {
    //     toast.error(error.errors?.details?.[0]?.message);
    //     setIsLoading(false);
    //   });
  };

  const onChangeFilter = (newFilters = {}) => {
    setFilter({ ...filter, ...newFilters });
    fetchOrdersList({ ...calcPagination(), ...filter, ...newFilters });
  };

  const isReadyToAdd = () => {
    const { name } = company;
    if (name) {
      return true;
    }
    return false;
  };

  const _renderModalAddCompany = () => {
    const inputFields = [
      { key: 'name', label: translate('common.name') },
      { key: 'description', label: translate('common.description') },
    ];
    return (
      <Modal visible={isVisibleCompany} onClose={() => setIsVisibleCompany(false)}>
        <div className='flex flex-col space-y-4 mb-6 px-8'>
          <h1 className='text-white font-black text-4xl text-center capitalize mb-4'>
            {translate('common.addNew')}
          </h1>
          {inputFields.map(({ key, label }) => (
            <div key={key}>
              <label htmlFor={key} className='block text-lg text-white'>
                {label}
              </label>
              <div className='mt-1'>
                {key === 'description' ? (
                  <textarea
                    id={key}
                    value={company[key as keyof typeof company]}
                    onChange={e => setCompany({ ...company, [key]: e.target.value })}
                    className='tw-input'
                    rows={4}
                    style={{ textAlign: 'left' }}
                  />
                ) : (
                  <input
                    type={['email', 'password'].includes(key) ? key : 'text'}
                    id={key}
                    value={company[key as keyof typeof company]}
                    onChange={e => setCompany({ ...company, [key]: e.target.value })}
                    className='tw-input'
                    style={{ textAlign: 'left' }}
                  />
                )}
              </div>
            </div>
          ))}
          <div className='self-center'>
            <button
              type='button'
              disabled={isLoading || isReadyToAdd() ? false : true}
              className='tw-button mt-5'
              onClick={onAddCompany}
            >
              {isLoading && <Spiner />}
              {translate('common.add')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderManageCompanies = () => {
    const columns = [
      { title: translate('common.name'), dataIndex: 'name' },
      {
        title: translate('common.email'),
        dataIndex: 'email',
        render: (_any: any, item: any) => {
          return <div>{item?.contact?.primary?.email || '-'}</div>;
        },
      },

      {
        title: translate('common.date'),
        dataIndex: 'time',
        render: (time: number) => moment(time).format('YYYY-MM-DD'),
      },
      {
        title: translate('common.status'),
        dataIndex: 'status',
        align: 'center' as AlignTypes,
        render: (status: string) => (
          <div className={`tw-status-btn ${getStatusClass(status)}`}>
            {translate(`common.${status}`)}
          </div>
        ),
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <div className='mt-6'>
        <div className=''>
          <div className='flex justify-between items-center p-5'>
            {/* <h1 className='font-black text-white text-xl'>
              {translate('companiesScreen.manageCompanies')}
            </h1> */}
            <Tabs
              activeKey={activeTab}
              onChange={tab => {
                setActiveTab(tab as TabEnums);
              }}
              tabPanes={[
                { key: 'producer', name: translate('common.lorem') },
                { key: 'packer', name: translate('common.lorem') },
              ]}
              className='mb-8'
            />
            <button
              type='button'
              className='rounded-btn-outline !text-xs'
              onClick={() => setIsVisibleCompany(true)}
            >
              {translate('common.addNew', { type: activeTab })}
            </button>
          </div>
          <div className='px-5 pb-5'>
            <OrdersFilters onChange={onChangeFilter} filter={filter} />
            <div className='mt-10 -mx-5'>
              <Table
                columns={columns}
                dataSource={companies}
                hover={true}
                onClickRow={(_index, row) => {
                  navigate(`${pathname}/${row._id}`);
                }}
              />
            </div>
          </div>
        </div>
        <Pagination
          count={countCompanies}
          limit={limit}
          skip={skip}
          onChange={pageParams => {
            fetchOrdersList({ ...filter, ...pageParams });
          }}
          className='mt-5'
        />
      </div>
    );
  };

  return (
    <div>
      {'comingSoon' ? (
        <div className=''>
          <ComingSoon />
        </div>
      ) : (
        <>
          {_renderModalAddCompany()}
          <div className='my-8'>{_renderManageCompanies()}</div>
        </>
      )}
    </div>
  );
};
