import React, { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { classNames } from 'utils/helperFunctions';
import { usePrevious } from 'utils/hooks/usePrevious';
import CheckedIcon from 'assets/images/CheckedIcon.svg';
// import CheckIcon from "assets/images/checkIcon.svg"
type Option = {
  value: string;
  label: string;
};
type Props = {
  options: Array<Option>;
  label?: JSX.Element;
  value?: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
  className?: string;
  classNameInput?: string;
  placeholder?: string;
  id?: string;
  textStyle?: React.CSSProperties;
  type?: string;
};
export const MultipleSelect: React.FC<Props> = React.memo(
  ({
    options,
    label,
    disabled = false,
    value,
    className,
    classNameInput,
    placeholder,
    onChange,
    id,
  }) => {
    const [query, setQuery] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<Array<Option>>([]);

    // Get the previous value (was passed into hook on last render)
    const prevValue = usePrevious(value);

    useEffect(() => {
      if (prevValue !== value) {
        const matchedOptions: Option[] = [];
        options.forEach(option => {
          if (value) {
            const matchedOption = value.find(item => item === option.value);
            if (matchedOption) {
              matchedOptions.push(option);
            }
          }
        });
        setSelectedOptions(matchedOptions);
      }
    }, [value, options]);

    const filteredOptions =
      query === ''
        ? options
        : options.filter(option => {
            return option.label.toLowerCase().includes(query.toLowerCase());
          });

    const getDisplayOptions = (displayOptions: Array<Option>) => {
      const displayStr = displayOptions.map(displayOption => displayOption.label).join(' - ');
      if (displayOptions.length > 3) {
        return `${displayOptions
          .map(displayOption => displayOption.label)
          .slice(0, 3)
          .join(' - ')} +${displayOptions.length - 3}...`;
      } else {
        return displayStr;
      }
    };

    return (
      <div className={`${className} rounded border border-white/[0.28]`}>
        <Combobox
          as='div'
          value={selectedOptions}
          onChange={(changeValue: Option[]) => {
            setSelectedOptions(changeValue);
            onChange(changeValue.map(option => option.value));
          }}
          multiple
        >
          {({ open }) => (
            <>
              {label ? (
                <Combobox.Label htmlFor={id} className='block text-sm'>
                  {label}
                </Combobox.Label>
              ) : null}

              <div className='relative'>
                <Combobox.Input
                  className={
                    classNameInput
                      ? classNameInput
                      : 'w-full rounded-md placeholder:!text-secondary-light border-none font-extrabold  text-white py-3 pl-3 shadow-sm focus:ring-0 focus:outline-none text-sm uppercase text-center bg-transparent tracking-[3.5px]'
                  }
                  onChange={event => setQuery(event.target.value)}
                  placeholder={placeholder}
                  disabled={disabled}
                  displayValue={getDisplayOptions}
                  id={id}
                />
                <Combobox.Button
                  className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ${
                    open ? 'transform rotate-180' : ''
                  }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='28'
                    viewBox='0 0 29 28'
                    fill='none'
                  >
                    <path
                      d='M28.0654 13.892C28.0654 6.23177 21.8351 -2.72337e-07 14.1765 -6.07105e-07C6.51791 -9.41872e-07 0.287576 6.23177 0.287576 13.892C0.287576 21.5522 6.51791 27.7841 14.1765 27.7841C21.8351 27.7841 28.0654 21.5523 28.0654 13.892ZM2.60251 13.892C2.60251 7.50868 7.79479 2.3152 14.1766 2.3152C20.5585 2.3152 25.7507 7.50868 25.7507 13.892C25.7507 20.2753 20.5585 25.4688 14.1766 25.4688C7.79479 25.4688 2.60251 20.2753 2.60251 13.892Z'
                      fill='white'
                    />
                    <path
                      d='M21.3607 11.8164C21.8128 11.3647 21.8128 10.631 21.3607 10.1793C20.9088 9.72703 20.1759 9.72703 19.724 10.1793L14.1766 15.7277L8.62923 10.1793C8.17734 9.72703 7.44441 9.72703 6.99252 10.1793C6.76645 10.4052 6.65354 10.7016 6.65354 10.9978C6.65354 11.294 6.76657 11.5905 6.99252 11.8164L13.3583 18.1836C13.81 18.6358 14.5431 18.6358 14.995 18.1836L21.3607 11.8164Z'
                      fill='white'
                    />
                  </svg>
                </Combobox.Button>

                {filteredOptions.length > 0 && (
                  <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-nell-black border border-nell-blue py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                    {filteredOptions.map((option: Option) => (
                      <Combobox.Option
                        key={option.value}
                        value={option}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-2 pr-4 tracking-[3.5px] font-black text-sm uppercase',
                            active ? 'bg-primary-light text-white' : 'text-primary-dark'
                          )
                        }
                      >
                        {({ selected }) => (
                          <div className='flex items-center space-x-2 '>
                            {selected ? (
                              <img
                                src={CheckedIcon}
                                className='border border-primary-dark rounded'
                              />
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='19'
                                height='19'
                                viewBox='0 0 19 19'
                                fill='none'
                                className='border border-primary-dark rounded'
                              >
                                <rect width='19' height='19' rx='4' fill='white' />
                              </svg>
                            )}
                            <span
                              className={classNames(
                                'block truncate',
                                selected ? 'font-semibold' : ''
                              )}
                            >
                              {option.label}
                            </span>
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </>
          )}
        </Combobox>
      </div>
    );
  }
);
