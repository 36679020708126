import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { calcPagination, getStatusClass, selectTransStyle, translate } from 'utils/helperFunctions';
import { AlignTypes, Column, Modal, Pagination, Spiner, Table } from 'components/shared';
import { Address, OrganizationServices } from 'services/apis/Organization';
import { OnboardingServices } from 'services/apis/Onboarding';
import { toast } from 'react-toastify';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { MultipleSelect } from 'components/shared/MultipleSelect';

export const OrdersDetails = () => {
  const { id } = useParams();
  const [companyProfile, setCompanyProfile] = useState<{ [key: string]: any }>({});
  const [isVisibleNewUser, setIsVisibleNewUser] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [newUser, setNewUser] = useState<any>({});
  const [companyGroups, setCompanyGroups] = useState<any[]>([]);
  const [isVisibleRemoveUser, setIsVisibleRemoveUser] = useState<boolean>(false);
  const [IsVisibleRemoveConfirm, setIsVisibleRemoveConfirm] = useState<boolean>(false);
  const [isVisableCompanyInfo, setIsVisableCompanyInfo] = useState<boolean>(true);
  const [isChangeAdminStatusModal, setIsChangeAdminStatusModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [countUsersPages, setCountUsersPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCompanyUsers = (params = calcPagination()) => {
    if (id) {
      OrganizationServices.fetchCompanyUsers(id, params)
        .then(res => {
          setUsers(res.items);
          setSelectedUser(res.items.find((item: any) => item._id === selectedUser._id) || {});
          setCountUsersPages(res.count);
          setCurrentPage(params.skip / params.limit + 1);
          return;
        })
        .catch(() => {});
    }
  };

  const addressKeys = ['address1', 'address2', 'city', 'country', 'state', 'zip'];

  const fetchCompany = () => {
    if (id) {
      OrganizationServices.fetchCompanyById(id)
        .then(res => {
          if (res) {
            setCompanyProfile(res);
          }
          return;
        })
        .catch(() => {});

      fetchCompanyUsers();

      OrganizationServices.fetchCompanyGroups(id)
        .then(res => {
          setCompanyGroups(res);
          return;
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const onUpdateCompanyProfile = async () => {
    // try {
    //   await OrganizationServices.updateCompanyProfile({
    //     ...companyProfile,
    //     id: companyProfile._id,
    //     knownAs: [''],
    //     address: { ...companyProfile?.address, country: '' },
    //   });
    //   toast.success(translate('profileScreen.accountHasBeenUpdatedSuccessfully'));
    //   setIsVisableCompanyInfo(!isVisableCompanyInfo);
    //   window.scrollTo({ top: 0, left: 0 });
    //   return;
    // } catch (error: any) {
    //   toast.error(error.errors?.details?.[0]?.message);
    // }
  };

  const ChangeAdminlocationStatus = async () => {
    if (companyProfile._id) {
      try {
        setIsLoading(true);
        if (companyProfile.status === 'active') {
          await OrganizationServices.ChangeCompanyStatus({
            ...companyProfile,
            status: 'inactive',
            id: companyProfile._id,
          });
        } else {
          await OrganizationServices.ChangeCompanyStatus({
            ...companyProfile,
            status: 'active',
            id: companyProfile._id,
          });
        }
        fetchCompany();
        setIsLoading(false);
        toast.success(translate('companiesScreen.updateStatus'));
        setIsChangeAdminStatusModal(false);
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const inviteUser = async () => {
    if (id) {
      try {
        const invitedUser = await OnboardingServices.inviteUserByCompany(id, { ...newUser });
        setUsers([...users, { ...newUser, _id: invitedUser.userId, status: 'active' }]);
        fetchCompanyUsers();
        toast.success(translate('settingsScreen.addNewUserToast'));
        setIsVisibleNewUser(false);
        setNewUser({});
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const unInviteUser = async () => {
    if (id) {
      try {
        await OrganizationServices.unInviteUserByCompany(id, selectedUser._id);
        const restUser = users.filter(user => {
          return user._id !== selectedUser._id;
        });
        fetchCompanyUsers();
        setUsers(restUser);
        setIsVisibleRemoveUser(false);
        setIsVisibleRemoveConfirm(false);
        toast.success(translate('settingsScreen.userDeleted'));
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const getSelectedUserGroups = (item: any): any => {
    const userGroup = item?.config?.allowedTenants?.find((tenant: any) => {
      return tenant?.tenant?.id === id;
    });
    return userGroup?.groups || [];
  };

  let timeOut: any;
  const updateUserRole = async (option: any) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      try {
        await OrganizationServices.updateUserRole(`${id}`, selectedUser._id, {
          groups: option ? option : [],
        });
        toast.success(translate('companiesScreen.userRoleUpdatedSuccessfully'));
        fetchCompanyUsers();
      } catch (error: any) {
        toast.error(error?.message);
      }
    }, 1500);
  };

  const _renderCompanyInfo = () => {
    return (
      <div className='bg-primary-light rounded-lg'>
        <div className='py-9 lg:px-13 grid grid-cols-1 lg:grid-cols-3 divide-y lg:divide-x lg:divide-y-0 divide-white divide-opacity-30 '>
          <div className='text-center flex flex-col justify-center items-center lg:pr-13'>
            <div>
              <img
                src={'https://i.ibb.co/Bw0thr9/Bitmap-2.jpg'}
                className='w-auto h-28 rounded-full inline-block'
                alt='img'
              />
            </div>
            <h1 className='font-black text-3xl pt-[14px] pb-7'>{companyProfile.name}</h1>
          </div>
          <div className='grid grid-cols-1 col-span-2 md:grid-cols-1 lg:grid-cols-3 grow'>
            <div className='mt-3 pt-5 pl-5 lg:pt-0 lg:pl-13 lg:col-span-2'>
              <ul className='list-none space-y-4'>
                <li>
                  <b>{translate('companiesScreen.bio')}:</b> {companyProfile.description || ''}
                </li>
                <li>
                  <b>{translate('companiesScreen.firstName')}:</b>{' '}
                  {companyProfile?.contact?.primary?.firstName || ''}
                </li>
                <li>
                  <b>{translate('companiesScreen.lastName')}:</b>{' '}
                  {companyProfile?.contact?.primary?.lastName || ''}
                </li>
                <li>
                  <b>{translate('companiesScreen.title')}:</b>{' '}
                  {companyProfile?.contact?.primary?.title || ''}
                </li>
                <li>
                  <b>{translate('common.email')}:</b>{' '}
                  {companyProfile?.contact?.primary?.email || ''}
                </li>
                <li>
                  <b>{translate('common.phone')}:</b>{' '}
                  {companyProfile?.contact?.primary?.phone.number || ''}
                </li>
                <li>
                  <b>{translate('common.address')}:</b>{' '}
                  {addressKeys
                    .filter(key => (companyProfile.address?.[key as keyof Address] ? true : false))
                    .map(key => companyProfile.address?.[key as keyof Address])
                    .join(', ') || ''}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className='flex flex-col md:flex-row lg:flex-row justify-end items-center md:pr-10 lg:pr-10 pb-8'>
            <button
              type='button'
              className='tw-button max-w-[164px]'
              onClick={() => {
                setIsVisableCompanyInfo(!isVisableCompanyInfo);
              }}
            >
              {translate('common.edit')}
            </button>
            <button
              type='button'
              className={`${
                companyProfile.status === 'active' ? '!bg-primary-green' : '!bg-primary-red'
              } tw-button max-w-[164px] mt-2 md:mt-0 lg:mt-0 lg:ml-3 md:ml-3 `}
              onClick={() => {
                setIsChangeAdminStatusModal(true);
              }}
            >
              {companyProfile.status === 'active'
                ? translate('common.active')
                : translate('common.inactive')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const _renderUsersTable = () => {
    const columns: Array<Column> = [
      {
        title: translate('common.name'),
        dataIndex: 'firstName',
        render: (_: any, item: any) => (
          <div className='flex items-center'>
            <div className='flex-shrink-0'>
              <img
                className='h-7 w-7 rounded-full'
                src={'https://via.placeholder.com/20x20.png'}
                alt={'icon'}
              />
            </div>
            <div className='ml-4 cursor-pointer'>
              <div
                onClick={() => {
                  setIsVisibleRemoveUser(true);
                  setSelectedUser(item);
                }}
              >
                {item?.firstName} {item?.lastName}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: translate('common.userType'),
        dataIndex: `type`,
        render: (_: any, item: any) => {
          const userGroup = getSelectedUserGroups(item);
          const res = [];
          const itemsPerRow = 3;
          for (let i = 0; i < userGroup?.length / itemsPerRow; i++) {
            res.push(
              <li key={i}>
                {userGroup.slice(i * itemsPerRow, itemsPerRow * i + itemsPerRow).join(' - ')}
              </li>
            );
          }
          return <ul className='list-none capitalize'>{res}</ul>;
        },
      },
      { title: translate('common.email'), dataIndex: 'email' },
      { title: translate('common.phone'), dataIndex: 'phone' },
      {
        title: translate('common.status'),
        dataIndex: 'status',
        align: 'center' as AlignTypes,
        render: (status: string) => {
          return (
            <div
              className={`rounded-md inline-flex items-center uppercase ${getStatusClass(
                status
              )} py-1 px-5 text-center tracking-[1.71429px] h-6 font-black text-[10px] leading-[22px] bg-white bg-opacity-[.08] border border-white border-opacity-20`}
            >
              {translate(`common.${status}`)}
            </div>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div className='bg-primary-light rounded mt-6'>
          <div className='flex justify-center sm:justify-between items-center p-5'>
            <div className='text-white/50 tracking-[2.2px] font-black text-sm uppercase ml-3 hidden sm:block'>
              {translate('companiesScreen.users')}
            </div>
            <div>
              <button
                type={'button'}
                className={'rounded-btn-outline'}
                onClick={() => setIsVisibleNewUser(true)}
              >
                {translate('companiesScreen.addNewUser')}
              </button>
            </div>
          </div>
          <Table columns={columns} dataSource={users} />
          <Pagination
            count={countUsersPages}
            limit={limit}
            skip={skip}
            onChange={pageParams => {
              fetchCompanyUsers(pageParams);
            }}
            className='mt-5 pr-4 pb-3'
          />
        </div>
      </>
    );
  };

  const _renderModalAddUser = () => {
    const inputFields = [
      { key: 'email', label: translate('common.enterEmail') },
      { key: 'firstName', label: translate('companiesScreen.firstName') },
      { key: 'lastName', label: translate('companiesScreen.lastName') },
      { key: 'phone', label: translate('common.phone') },
      { key: 'password', label: translate('settingsScreen.passwordOptional') },
      { key: 'groups', label: translate('settingsScreen.specifyRole') },
    ];
    return (
      <Modal
        visible={isVisibleNewUser}
        onClose={() => {
          setIsVisibleNewUser(false);
        }}
      >
        <div className='text-white px-10 pb-7'>
          <h1 className='font-black text-3xl text-white text-center mb-9'>
            {translate('settingsScreen.addUser')}
          </h1>
          <div className='flex flex-col'>
            {inputFields.map(({ key, label }) => {
              if (key === 'groups') {
                return (
                  <div key={key} className=''>
                    <MultipleSelect
                      options={[...companyGroups]?.map((group: any) => ({
                        label: group?.name,
                        value: group?.code,
                      }))}
                      onChange={(option: any) => {
                        setNewUser({ ...newUser, [key]: option });
                      }}
                      value={newUser?.[key] || ''}
                      placeholder={label}
                      // className={'tw-input'}
                      classNameInput={
                        'w-full rounded-md border-none font-black border-gray-300 text-black py-3 pl-3 shadow-sm focus:ring-0 focus:outline-none text-sm uppercase text-center -m-3 bg-transparent tracking-[3.5px]'
                      }
                      // textStyle={selectTransStyle}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={key} className='mb-5'>
                    <input
                      type={key === 'password' ? 'password' : 'text'}
                      name={key}
                      onChange={e => setNewUser({ ...newUser, [key]: e.target.value })}
                      className='tw-input'
                      placeholder={label}
                    />
                  </div>
                );
              }
            })}
            <div className='self-center'>
              <button type='button' className='tw-button mt-8' onClick={inviteUser}>
                {translate('common.sendInvite')}
              </button>
            </div>
          </div>
          <div className='text-xs mt-6 italic text-center'>
            {translate('companiesScreen.defaultPasswordHint')}{' '}
            <span className='font-bold'>{'password'}</span>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderModalRemoveUser = () => {
    return (
      <Modal
        visible={isVisibleRemoveUser}
        onClose={() => {
          setIsVisibleRemoveUser(false);
        }}
      >
        <div className='text-white px-10'>
          <h1 className='font-black text-3xl text-white text-center mb-9'>
            {selectedUser.firstName} {selectedUser.lastName}
          </h1>

          <div className='grid grid-cols-2 gap-3 item-center justify-center mb-3'>
            <div>
              <div className='flex items-center space-x-2 justify-center rounded uppercase py-2 px-6 text-center tracking-[3.5px] font-medium text-sm border border-white/[0.28] h-12'>
                <PhoneIcon className='w-5 h-5' />
                <p>{translate('common.phone')}</p>
              </div>
            </div>
            <div>
              <div className='flex items-center space-x-2 justify-center rounded uppercase py-2 px-6 text-center tracking-[3.5px] font-medium text-sm border border-white/[0.28] h-12'>
                <MailIcon className='w-5 h-5' />
                <p>{translate('common.email')}</p>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <MultipleSelect
              options={[...companyGroups]?.map((group: any) => ({
                label: group?.name,
                value: group?.code,
              }))}
              placeholder={translate('settingsScreen.specifyRole')}
              onChange={updateUserRole}
              classNameInput={
                'tw-input !bg-transparent !border-opacity-50 h-12 !text-white uppercase tracking-[3.5px] !font-medium !text-sm'
              }
              value={getSelectedUserGroups(selectedUser)}
              textStyle={selectTransStyle}
            />
          </div>
          <div>
            <button
              type='button'
              onClick={() => setIsVisibleRemoveConfirm(true)}
              className={`flex items-center text-primary-red space-x-2 w-full justify-center rounded uppercase p-2 px-2 text-center tracking-[3.5px] font-black text-sm border border-white/[0.28] h-12`}
            >
              <p>{translate('settingsScreen.removeUser')} </p>
            </button>
          </div>

          {IsVisibleRemoveConfirm && (
            <div className='mt-9'>
              <span className='text-xs italic text-center block mb-4'>
                {translate('settingsScreen.removeUserConfirmText')}
              </span>
              <div className='grid grid-cols-2 gap-3 items-center justify-center'>
                <div>
                  <button
                    type='button'
                    className='flex item-center justify-center rounded-[10px] w-full uppercase py-3 px-6 text-center tracking-[3.5px] font-medium text-sm border border-white h-12'
                    onClick={() => {
                      setIsVisibleRemoveUser(false);
                      setIsVisibleRemoveConfirm(false);
                    }}
                  >
                    <p>{translate('common.cancel')}</p>
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='flex items-center justify-center text-black bg-primary-red w-full rounded-[10px] uppercase py-3 px-6 text-center tracking-[3.5px] font-medium text-sm h-12'
                    onClick={unInviteUser}
                  >
                    {translate('common.confirm')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const updateCompanyProfile = () => {
    return (
      <>
        <div
          className='bg-primary-light p-5 text-white mt-10 rounded'
          style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15266' }}
        >
          <div className='text-white/50 tracking-[2.2px] font-black text-sm uppercase'>
            {translate('companiesScreen.bio')}
          </div>
          <div className='inline-grid justify-center m-5'>
            <div>
              <img
                src={'https://i.ibb.co/Bw0thr9/Bitmap-2.jpg'}
                className='w-auto h-20 rounded-full inline-block'
                alt='img'
              />
            </div>
            <div className='text-secondary-color underline mt-2 flex justify-center text-sm cursor-pointer'>
              {translate('settingsScreen.editPhoto')}
            </div>
          </div>
          <div className='mt-6'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
              <div>
                <div className='flex justify-between items-center'>
                  <label htmlFor='_name' className='block text-sm font-bold text-white/[0.65]'>
                    {translate('companiesScreen.companyName')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    value={companyProfile?.name || ''}
                    onChange={e => setCompanyProfile({ ...companyProfile, name: e.target.value })}
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
              <div>
                <div className='flex justify-between items-center'>
                  <label htmlFor='email' className='block text-sm font-bold text-white/[0.65]'>
                    {translate('settingsScreen.contactemail')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='email'
                    name='email'
                    id='email'
                    value={companyProfile?.contact?.primary?.email || ''}
                    onChange={e =>
                      setCompanyProfile({
                        ...companyProfile,
                        contact: {
                          ...companyProfile.contact,
                          primary: { ...companyProfile.contact.primary, email: e.target.value },
                        },
                      })
                    }
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
              <div>
                <div className='flex justify-between items-center text-white/[0.65]'>
                  <label htmlFor='_phone' className='block text-sm font-bold'>
                    {translate('settingsScreen.ContactPhoneNumber')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='phone'
                    id='phone'
                    value={companyProfile?.contact?.primary?.phone?.number || ''}
                    onChange={e =>
                      setCompanyProfile({
                        ...companyProfile,
                        contact: {
                          ...companyProfile.contact,
                          primary: {
                            ...companyProfile.contact.primary,
                            phone: {
                              ...companyProfile.contact.primary.phone,
                              number: e.target.value,
                            },
                          },
                        },
                      })
                    }
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
              <div>
                <div className='flex justify-between items-center text-white/[0.65]'>
                  <label htmlFor='title' className='block text-sm font-bold'>
                    {translate('companiesScreen.contactTitle')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='title'
                    id='title'
                    value={companyProfile?.contact?.primary?.title || ''}
                    onChange={e =>
                      setCompanyProfile({
                        ...companyProfile,
                        contact: {
                          ...companyProfile.contact,
                          primary: {
                            ...companyProfile.contact.primary,
                            title: e.target.value,
                          },
                        },
                      })
                    }
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
              <div>
                <div className='flex justify-between items-center text-white/[0.65]'>
                  <label htmlFor='firstName' className='block text-sm font-bold'>
                    {translate('settingsScreen.ContactFirstName')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='firstName'
                    id='firstName'
                    value={companyProfile?.contact?.primary?.firstName || ''}
                    onChange={e =>
                      setCompanyProfile({
                        ...companyProfile,
                        contact: {
                          ...companyProfile.contact,
                          primary: {
                            ...companyProfile.contact.primary,
                            firstName: e.target.value,
                          },
                        },
                      })
                    }
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
              <div>
                <div className='flex justify-between items-center text-white/[0.65]'>
                  <label htmlFor='lastName' className='block text-sm font-bold'>
                    {translate('settingsScreen.ContactLastName')}
                  </label>
                </div>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='lastName'
                    id='lastName'
                    value={companyProfile?.contact?.primary?.lastName || ''}
                    onChange={e =>
                      setCompanyProfile({
                        ...companyProfile,
                        contact: {
                          ...companyProfile.contact,
                          primary: {
                            ...companyProfile.contact.primary,
                            lastName: e.target.value,
                          },
                        },
                      })
                    }
                    className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex justify-between items-center text-white/[0.65]'>
              <label htmlFor='_bio' className='block text-sm font-bold'>
                {translate('settingsScreen.bioDescription')}
              </label>
            </div>
            <div className='mt-1'>
              <textarea
                name='_bio'
                id='_bio'
                value={companyProfile?.description || ''}
                onChange={e =>
                  setCompanyProfile({ ...companyProfile, description: e.target.value })
                }
                className='tw-input min-h-[100px] text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        </div>
        <div
          className='bg-primary-light p-5 text-white mt-10 rounded'
          style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15266' }}
        >
          <div className='text-white/50 tracking-[2.2px] font-black text-sm uppercase mb-4'>
            {translate('profileScreen.address')}
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-5 items-center'>
            <div>
              <label htmlFor='_address1' className='block text-sm font-bold text-white/[0.65]'>
                {translate('profileScreen.address1')}
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  id='address1'
                  value={companyProfile?.address?.address1 || ''}
                  onChange={e =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: { ...companyProfile.address, address1: e.target.value },
                    })
                  }
                  className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div>
              <label htmlFor='_address2' className='block text-sm font-bold text-white/[0.65]'>
                {translate('profileScreen.address2')}
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  id='_address2'
                  value={companyProfile?.address?.address2 || ''}
                  onChange={e =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: { ...companyProfile.address, address2: e.target.value },
                    })
                  }
                  className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div>
              <label htmlFor='_city' className='block text-sm font-bold text-white/[0.65]'>
                {translate('profileScreen.city')}
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  id='city'
                  value={companyProfile?.address?.city || ''}
                  onChange={e =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: { ...companyProfile.address, city: e.target.value },
                    })
                  }
                  className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div>
              <label htmlFor='_state' className='block text-sm font-bold text-white/[0.65]'>
                {translate('profileScreen.state')}
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  id='state'
                  value={companyProfile?.address?.state || ''}
                  onChange={e =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: { ...companyProfile.address, state: e.target.value },
                    })
                  }
                  className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
            <div>
              <label htmlFor='_zipCode' className='block text-sm font-bold text-white/[0.65]'>
                {translate('profileScreen.zipCode')}
              </label>
              <div>
                <input
                  type='text'
                  id='zip'
                  value={companyProfile?.address?.zip || ''}
                  onChange={e =>
                    setCompanyProfile({
                      ...companyProfile,
                      address: { ...companyProfile.address, zip: e.target.value },
                    })
                  }
                  className='tw-input text-gray-900 disabled:bg-white disabled:opacity-[0.35]'
                  style={{ textAlign: 'left' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='pt-7 flex justify-end mx-5'>
          <button
            type='button'
            className='tw-button'
            onClick={() => {
              onUpdateCompanyProfile();
            }}
          >
            {translate('common.save')}
          </button>
        </div>
      </>
    );
  };

  const _renderModalChangeAdminStatus = () => {
    return (
      <Modal
        visible={isChangeAdminStatusModal}
        onClose={() => {
          setIsChangeAdminStatusModal(false);
        }}
      >
        <div className='text-white px-10'>
          <h1 className='font-black text-3xl text-white text-center mb-9'>
            {translate('companiesScreen.changeWarehouseStatus')}{' '}
            {companyProfile.status === 'active' ? (
              <span className='text-primary-red'>{translate('common.inactive')}</span>
            ) : (
              <span className='text-primary-green'>{translate('common.active')}</span>
            )}
          </h1>
          <div className='grid grid-cols-2 gap-3 items-center justify-center'>
            <div>
              <button
                type='button'
                className='flex item-center justify-center rounded-[10px] w-full uppercase py-3 px-6 text-center tracking-[3.5px] font-medium text-sm border border-white h-12'
                onClick={() => {
                  setIsChangeAdminStatusModal(false);
                }}
              >
                <p>{translate('common.cancel')}</p>
              </button>
            </div>
            <div>
              <button
                type='button'
                className={`
                    flex items-center justify-center text-black w-full rounded-[10px] uppercase py-3 px-6 text-center tracking-[3.5px] font-medium text-sm h-12 ${
                      companyProfile.status === 'active' ? 'bg-primary-red' : 'bg-primary-green'
                    }`}
                onClick={ChangeAdminlocationStatus}
              >
                {isLoading && <Spiner />}
                {translate('common.confirm')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {isVisableCompanyInfo && _renderCompanyInfo()}
      {isVisableCompanyInfo && _renderUsersTable()}
      {!isVisableCompanyInfo && updateCompanyProfile()}
      {isVisibleNewUser && _renderModalAddUser()}
      {isVisibleRemoveUser && _renderModalRemoveUser()}
      {isChangeAdminStatusModal && _renderModalChangeAdminStatus()}
    </>
  );
};
