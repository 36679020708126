import React, { useEffect, useState } from 'react';
import { translate } from 'utils/helperFunctions';
import { CustomSelect, Modal, Spiner } from 'components/shared';
import { toast } from 'react-toastify';
import { KbServices } from 'services/apis/Kb';
import { Importer, ImporterField } from 'react-csv-importer';
import '../../components/componentStyle/Styles.css';

type Option = {
  label: string;
  value: string;
};

type Props = {
  isLoading: boolean;
  isVisibleAddBulkTags: boolean;
  setIsVisibleAddBulkTags: (visible: boolean) => void;
  setNewBulkTags: (e: any) => void;
  newBulkTags: { [key: string]: any };
  onAddBulkTags: any;
  getCompanies: () => Option[];
};

export const AddBulkTags = (props: Props) => {
  const {
    isLoading,
    isVisibleAddBulkTags,
    setIsVisibleAddBulkTags,
    setNewBulkTags,
    newBulkTags,
    onAddBulkTags,
    getCompanies,
  } = props;
  const [options, setoptions] = useState<{ [key: string]: any }>({});
  const [showImporter, setShowImporter] = useState<boolean>(true);

  const fetchTagOptions = async () => {
    try {
      const opts = await KbServices.tagOptions();
      setoptions(opts);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchTagOptions();
  }, []);

  const checkValidation = () => {
    return (
      !newBulkTags.batteryType ||
      !newBulkTags.driver ||
      !newBulkTags.network ||
      !newBulkTags.networkConnectionId ||
      !newBulkTags.region
    );
  };

  const handleDataImport = async (rows: any) => {
    setNewBulkTags((prevBulkTags: any) => ({
      ...prevBulkTags,
      tags: [...prevBulkTags.tags, ...rows],
    }));
    await new Promise(resolve => setTimeout(resolve, 500));
  };

  return (
    <Modal
      visible={isVisibleAddBulkTags}
      modalTitle={translate('factoryTags.newBulkTags')}
      width={630}
      onClose={() => {
        setIsVisibleAddBulkTags(false);
        setNewBulkTags({ tags: [] });
        setShowImporter(true);
      }}
    >
      {showImporter ? (
        <div className='px-4 sm:px-6 '>
          <label htmlFor='devEUI' className='block text-sm text-nell-blue mb-2'>
            CSV Importer
          </label>
          <Importer
            dataHandler={handleDataImport}
            chunkSize={10000}
            defaultNoHeader={false}
            onClose={() => {
              setShowImporter(false);
            }}
            skipEmptyLines={true}
          >
            <ImporterField name='devEUI' label='devEUI' />
            <ImporterField name='appEUI' label='appEUI' />
            <ImporterField name='appKey' label='appKey' />
            <ImporterField name='serialnumber' label='serial number' optional />
          </Importer>
        </div>
      ) : (
        <>
          <div className='flex flex-col gap-5 items-start justify-start px-4 sm:px-6 '>
            <div className='w-full'>
              <label className='block text-sm text-nell-blue mb-2'>
                {translate('factoryTags.company')}
              </label>
              <CustomSelect
                options={getCompanies()}
                value={newBulkTags?.companyId || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, companyId: e && e.value });
                }}
                placeholder={translate('factoryTags.companyPlaceholder')}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='driver' className='block text-sm text-nell-blue mb-2'>
                *{translate('factoryTags.driver')}
              </label>
              <CustomSelect
                options={
                  (options &&
                    options?.drivers?.map((opt: any) => ({ label: opt.name, value: opt.code }))) ||
                  []
                }
                value={newBulkTags?.driver || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, driver: e && e.value });
                }}
                placeholder={translate('factoryTags.driverPlaceHolder')}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='region' className='block text-sm text-nell-blue mb-2'>
                *{translate('factoryTags.region')}
              </label>
              <CustomSelect
                options={
                  (options &&
                    options?.regions?.map((opt: any) => ({ label: opt.name, value: opt.code }))) ||
                  []
                }
                placeholder={translate('factoryTags.regionPlaceHolder')}
                value={newBulkTags?.region || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, region: e && e.value });
                }}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='network' className='block text-sm text-nell-blue mb-2'>
                *{translate('factoryTags.network')}
              </label>
              <CustomSelect
                options={
                  (options &&
                    options?.networks?.map((opt: any) => ({ label: opt.name, value: opt.code }))) ||
                  []
                }
                placeholder={translate('factoryTags.networkPlaceHolder')}
                value={newBulkTags?.network || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, network: e && e.value });
                }}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='networkConnection' className='block text-sm text-nell-blue mb-2'>
                *{translate('factoryTags.networkConnection')}
              </label>
              <CustomSelect
                options={
                  (options &&
                    options?.networkConnections?.map((opt: any) => ({
                      label: opt.name,
                      value: opt._id,
                    }))) ||
                  []
                }
                placeholder={translate('factoryTags.networkConnectionPlaceHolder')}
                value={newBulkTags?.networkConnectionId || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, networkConnectionId: e && e.value });
                }}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='batteryType' className='block text-sm text-nell-blue mb-2'>
                *{translate('factoryTags.batteryType')}
              </label>
              <CustomSelect
                options={
                  (options &&
                    options?.batteryTypes?.map((opt: any) => ({
                      label: opt.name,
                      value: opt.code,
                    }))) ||
                  []
                }
                placeholder={translate('factoryTags.batteryTypePlaceHolder')}
                value={newBulkTags?.batteryType || ''}
                onChange={e => {
                  setNewBulkTags({ ...newBulkTags, batteryType: e && e.value });
                }}
              />
            </div>
          </div>
          <div className='flex justify-center px-4 sm:px-6 mt-8'>
            <button
              className='tw-button w-full'
              onClick={onAddBulkTags}
              disabled={checkValidation()}
            >
              {isLoading && <Spiner className='ml-40' />}
              {translate('factoryTags.addTag')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
