import React, { useEffect, useState } from 'react';
import { translate } from 'utils/helperFunctions';
import { CustomSelect, Modal, Spiner } from 'components/shared';
import { toast } from 'react-toastify';
import { KbServices } from 'services/apis/Kb';
import {
  BatteryType,
  Driver,
  Network,
  NetworkConnection,
  Region,
  Tag,
} from 'services/apis/Tag/Tag.types';

interface Option {
  networkConnections: NetworkConnection[];
  drivers: Driver[];
  networks: Network[];
  regions: Region[];
  batteryTypes: BatteryType[];
}

interface Props {
  isLoading: boolean;
  isVisibleAddTag: boolean;
  setIsVisibleAddTag: (visible: boolean) => void;
  setNewTag: (e: Tag) => void;
  newTag: Tag;
  onAddTag: () => void;
  getCompanies: any;
}

export const AddTag: React.FC<Props> = ({
  isLoading,
  isVisibleAddTag,
  setIsVisibleAddTag,
  setNewTag,
  newTag,
  onAddTag,
  getCompanies,
}) => {
  const [options, setOptions] = useState<Option>({
    networkConnections: [],
    drivers: [],
    networks: [],
    regions: [],
    batteryTypes: [],
  });

  const fetchTagOptions = async () => {
    try {
      const opts = await KbServices.tagOptions();
      setOptions(opts);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchTagOptions();
  }, []);

  const checkValidation = () => {
    return (
      !newTag.appEUI ||
      !newTag.appKey ||
      !newTag.batteryType ||
      !newTag.devEUI ||
      !newTag.driver ||
      !newTag.network ||
      !newTag.networkConnectionId ||
      !newTag.region
    );
  };

  return (
    <Modal
      visible={isVisibleAddTag}
      modalTitle={translate('factoryTags.newTag')}
      onClose={() => {
        setIsVisibleAddTag(false);
        setNewTag({
          appEUI: '',
          appKey: '',
          batteryType: '',
          devEUI: '',
          driver: '',
          network: '',
          networkConnectionId: '',
          region: '',
          serialnumber: '',
        });
      }}
    >
      <div className=''>
        <div className='flex flex-col gap-5 items-start justify-start px-4 sm:px-6 '>
          <div className='w-full'>
            <label htmlFor='devEUI' className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.devEUI')}
            </label>
            <input
              type='text'
              name='devEUI'
              id='devEUI'
              required
              autoComplete='off'
              placeholder={translate('factoryTags.devEUIPlaceHolder')}
              value={newTag?.devEUI || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTag({ ...newTag, devEUI: e.target.value });
              }}
              className='tw-input'
            />
          </div>
          <div className='w-full'>
            <label htmlFor='appEUI' className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.appEUI')}
            </label>
            <input
              type='text'
              name='appEUI'
              id='appEUI'
              required
              autoComplete='off'
              placeholder={translate('factoryTags.appEUIPlaceHolder')}
              value={newTag?.appEUI || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTag({ ...newTag, appEUI: e.target.value });
              }}
              className='tw-input'
            />
          </div>
          <div className='w-full'>
            <label htmlFor='appkey' className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.appkey')}
            </label>
            <input
              type='text'
              name='appkey'
              id='appkey'
              required
              autoComplete='off'
              placeholder={translate('factoryTags.appkeyPlaceHolder')}
              value={newTag?.appKey || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTag({ ...newTag, appKey: e.target.value });
              }}
              className='tw-input'
            />
          </div>
          <div className='w-full'>
            <label htmlFor='serialNumber' className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.serialNumber')}
            </label>
            <input
              type='text'
              name='serialNumber'
              id='serialNumber'
              autoComplete='off'
              required
              placeholder={translate('factoryTags.serialNumberPlaceHolder')}
              value={newTag?.serialnumber || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTag({ ...newTag, serialnumber: e.target.value });
              }}
              className='tw-input'
            />
          </div>
        </div>
        <hr className='border-1 my-8 border-nell-light-grey w-full' />
        <div className='flex flex-col gap-5 items-start justify-start px-4 sm:px-6 '>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              {translate('factoryTags.company')}
            </label>
            <CustomSelect
              options={getCompanies()}
              value={newTag?.companyId || ''}
              onChange={e => {
                setNewTag({ ...newTag, companyId: e && e.value.toString() });
              }}
              placeholder={translate('factoryTags.companyPlaceholder')}
            />
          </div>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.driver')}
            </label>
            <CustomSelect
              options={
                (options &&
                  options?.drivers?.map((opt: Driver) => ({ label: opt.name, value: opt.code }))) ||
                []
              }
              value={newTag?.driver || ''}
              onChange={e => {
                setNewTag({ ...newTag, driver: e && e.value.toString() });
              }}
              placeholder={translate('factoryTags.driverPlaceHolder')}
            />
          </div>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.region')}
            </label>
            <CustomSelect
              options={
                (options &&
                  options?.regions?.map((opt: Region) => ({ label: opt.name, value: opt.code }))) ||
                []
              }
              placeholder={translate('factoryTags.regionPlaceHolder')}
              value={newTag?.region || ''}
              onChange={e => {
                setNewTag({ ...newTag, region: e && e.value.toString() });
              }}
            />
          </div>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.network')}
            </label>
            <CustomSelect
              options={
                (options &&
                  options?.networks?.map((opt: Network) => ({
                    label: opt.name,
                    value: opt.code,
                  }))) ||
                []
              }
              placeholder={translate('factoryTags.networkPlaceHolder')}
              value={newTag?.network || ''}
              onChange={e => {
                setNewTag({ ...newTag, network: e && e.value.toString() });
              }}
            />
          </div>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.networkConnection')}
            </label>
            <CustomSelect
              options={
                (options &&
                  options?.networkConnections?.map((opt: NetworkConnection) => ({
                    label: opt.name,
                    value: opt._id,
                  }))) ||
                []
              }
              placeholder={translate('factoryTags.networkConnectionPlaceHolder')}
              value={newTag?.networkConnectionId || ''}
              onChange={e => {
                setNewTag({ ...newTag, networkConnectionId: e && e.value.toString() });
              }}
            />
          </div>
          <div className='w-full'>
            <label className='block text-sm text-nell-blue mb-2'>
              *{translate('factoryTags.batteryType')}
            </label>
            <CustomSelect
              options={
                (options &&
                  options?.batteryTypes?.map((opt: BatteryType) => ({
                    label: opt.name,
                    value: opt.code,
                  }))) ||
                []
              }
              placeholder={translate('factoryTags.batteryTypePlaceHolder')}
              value={newTag?.batteryType || ''}
              onChange={e => {
                setNewTag({ ...newTag, batteryType: e && e.value.toString() });
              }}
            />
          </div>
        </div>
        <div className='flex justify-center px-4 sm:px-6 mt-8'>
          <button className='tw-button w-full' onClick={onAddTag} disabled={checkValidation()}>
            {isLoading && <Spiner className='ml-40' />}
            {translate('factoryTags.addTag')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
