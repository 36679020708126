import axios from 'axios';

type GeneralParams = {
  [key: string]: any;
};

export const CommerceServices = {
  fetchOrders: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `commerce/orders`;
      const orders = await axios.get(endPoint, { params });
      return orders;
    } catch (error) {
      throw error;
    }
  },
};
