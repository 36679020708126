import { translate } from '../../utils/helperFunctions';
import { ReactComponent as DashboardIcon } from 'assets/images/DashboardNavIcon.svg';
import { ReactComponent as DashboardIconActive } from 'assets/images/DashboardIconActive.svg';
import { ReactComponent as OrdersIcon } from 'assets/images/OrdersNavIcon.svg';
import { ReactComponent as OrdersIconActive } from 'assets/images/OrdersNavIconActive.svg';
import { ReactComponent as TagsIconActive } from 'assets/images/TagsNavIconActive.svg';
import { ReactComponent as TagsIcon } from 'assets/images/TagsNavIcon.svg';
import { ReactComponent as DownLinksIconActive } from 'assets/images/DownLinksNavIconActive.svg';
import { ReactComponent as DownLinksIcon } from 'assets/images/DownLinksNavIcon.svg';
import { ReactComponent as SettingsIconActive } from 'assets/images/SettingsNavIconActive.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/SettingsNavIcon.svg';
import { ReactComponent as FactoryTagsIcon } from 'assets/images/FactoryTagsNavIcon.svg';
import { ReactComponent as FactoryTagsIconActive } from 'assets/images/FactoryTagsNavIconActive.svg';
import { ReactComponent as OrganizationIcon } from 'assets/images/OrganizationIcon.svg';
import { ReactComponent as OrganizationIconActive } from 'assets/images/OrganizationIconActive.svg';

export type ISidebarRoute = {
  name: string;
  path: string;
  icon: (isActive: boolean) => JSX.Element;
};

export const sideBarRoutes = (): ISidebarRoute[] => [
  {
    name: translate('common.dashboard'),
    path: '/dashboard',
    icon: isActive =>
      isActive ? (
        <DashboardIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <DashboardIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.orders'),
    path: '/dashboard/orders',
    icon: isActive =>
      isActive ? (
        <OrdersIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <OrdersIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.factorytags'),
    path: '/dashboard/factorytags',
    icon: isActive =>
      isActive ? (
        <FactoryTagsIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <FactoryTagsIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.tags'),
    path: '/dashboard/tags',
    icon: isActive =>
      isActive ? (
        <TagsIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <TagsIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.organizations'),
    path: '/dashboard/organizations',
    icon: isActive =>
      isActive ? (
        <OrganizationIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <OrganizationIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.downlinks'),
    path: '/dashboard/downlinks',
    icon: isActive =>
      isActive ? (
        <DownLinksIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <DownLinksIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
  {
    name: translate('common.settings'),
    path: '/dashboard/settings',
    icon: isActive =>
      isActive ? (
        <SettingsIconActive fill='#010505' className={`flex-shrink-0 h-6 w-6`} />
      ) : (
        <SettingsIcon fill='#FAFFFF' className={`flex-shrink-0 h-6 w-6`} />
      ),
  },
];

export const getPathTitle = (pathname: string): string | JSX.Element => {
  try {
    const title: string = pathname.split('/')[2] || 'dashboard';
    return translate(`common.${title}`);
  } catch (error) {
    return translate('common.dashboard');
  }
};
