import { ReAuthData, UserAccountProperties } from 'services/apis/Auth';

export const actions = {
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
};
export const setLoginData = (payload: ReAuthData | null) => ({
  type: actions.SET_LOGIN_DATA,
  payload,
});

export const setIsLoggedIn = (payload: boolean) => ({
  type: actions.SET_IS_LOGGED_IN,
  payload,
});

export const updateUserAccount = (payload: UserAccountProperties) => ({
  type: actions.UPDATE_USER_ACCOUNT,
  payload,
});
export const switchLanguage = (language: 'en' | 'es') => ({
  type: actions.SWITCH_LANGUAGE,
  language: language,
});
