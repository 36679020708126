import { useEffect, useState } from 'react';
import { translate } from 'utils/helperFunctions';
import { Loader, Table } from 'components/shared';
import { OrganizationServices } from 'services/apis/Organization';
import { useParams } from 'react-router-dom';

export const OrganizationDetailsRoles = () => {
  const { id } = useParams();
  const [companyGroups, setCompanyGroups] = useState<any[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(true);

  const fetchCompanyRoles = async () => {
    try {
      if (id) {
        const roles = await OrganizationServices.fetchCompanyGroups(id);
        setIsLoader(false);
        setCompanyGroups(roles);
      }
    } catch (err) {
      setIsLoader(false);
      throw err;
    }
  };

  useEffect(() => {
    fetchCompanyRoles();
  }, []);

  const _renderGroupTable = () => {
    const columns = [
      {
        title: (
          <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
            {translate('common.name')}
          </div>
        ),
        dataIndex: 'name',
        theadClass: 'w-[100px]',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div>{item?.name}</div>
          </div>
        ),
      },
      {
        title: translate('common.description'),
        dataIndex: 'description',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div>{item?.description}</div>
          </div>
        ),
      },
    ];

    const _renderRoleForMobile = () => {
      return (
        <>
          {isLoader ? (
            <div
              className='flex justify-center items-center xh-screen w-full m-auto'
              style={{ height: `calc(100vh - 170px)` }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {companyGroups.map(role => {
                return (
                  <div
                    key={role._id}
                    className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-4'
                  >
                    <div className='flex justify-between'>
                      <h2 className='font-heavyGaret capitalize cursor-pointer'>{role.name}</h2>
                    </div>
                    <p className='h-[50px] ellipsis cursor-pointer'>{role.description}</p>
                  </div>
                );
              })}
              ;
            </>
          )}
        </>
      );
    };

    return (
      <>
        <div className='pb-4 mt-7' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
          <div className='hidden sm:block w-full'>
            {isLoader ? (
              <div
                className='flex justify-center items-center xh-screen w-full m-auto'
                style={{ height: `calc(100vh - 170px)` }}
              >
                <Loader />
              </div>
            ) : (
              <Table columns={columns} dataSource={companyGroups} hover />
            )}
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderRoleForMobile()}</div>
        </div>
      </>
    );
  };

  return <div>{_renderGroupTable()}</div>;
};
