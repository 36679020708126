import axios from 'axios';

type GeneralParams = {
  [key: string]: any;
};
export const OrganizationServices = {
  fetchCompanies: async (type: 'company', params: GeneralParams = {}): Promise<any> => {
    const endPoint = `organization/companies/${type}`;
    try {
      const companies = await axios.get(endPoint, { params });
      return companies;
    } catch (error) {
      throw error;
    }
  },
  addCompany: async (type: 'company', payload: object): Promise<any> => {
    try {
      const endPoint = `organization/company/${type}`;
      const response = await axios.post(endPoint, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyById: async (companyId: string): Promise<any> => {
    try {
      const endPoint = `organization/company/${companyId}`;
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyGroups: async (companyId: string): Promise<any> => {
    try {
      const endPoint = `organization/company/${companyId}/groups`;
      const companyGroups = await axios.get(endPoint);
      return companyGroups;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyUsers: async (companyId: string, params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `organization/company/${companyId}/users`;
      const companyUsers = await axios.get(endPoint, { params });
      return companyUsers;
    } catch (error) {
      throw error;
    }
  },
  unInviteUserByCompany: async (companyId: string, userId: string): Promise<any> => {
    const endPoint = `organization/company/${companyId}/user/${userId}`;
    try {
      const unInvitedUser = await axios.delete(endPoint);
      return unInvitedUser;
    } catch (error) {
      throw error;
    }
  },
  updateUserRole: async (companyId: string, userId: string, payload: any): Promise<any> => {
    const endPoint = `organization/company/${companyId}/user/${userId}/groups`;
    try {
      const updatedUserRole = await axios.put(endPoint, payload);
      return updatedUserRole;
    } catch (error) {
      throw error;
    }
  },
  updateCompanyProfile: async (companyId: string, payload: any): Promise<any> => {
    try {
      const endPoint = `organization/company/${companyId}`;
      const response = await axios.put(endPoint, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  ChangeCompanyStatus: async (payload: any) => {
    try {
      const endPoint = `organization/admin/company/status`;
      const adminLocation = await axios.put(endPoint, payload);
      return adminLocation;
    } catch (error) {
      throw error;
    }
  },
  ChangeOrganizationStatus: async (companyId: string, payload: any) => {
    try {
      const endPoint = `organization/company/${companyId}/status`;
      const adminLocation = await axios.put(endPoint, payload);
      return adminLocation;
    } catch (error) {
      throw error;
    }
  },
  fetchGroupProducts: async (): Promise<any> => {
    const endPoint = `organization/product`;
    try {
      const products = await axios.get(endPoint);
      return products;
    } catch (error) {
      throw error;
    }
  },
};
