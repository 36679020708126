import i18n from 'i18n';
import { actions } from './actions';

export const initStateAuth: {
  loginData: any | null;
  userData: any;
  isLoggedIn: boolean;
  language: string;
} = {
  loginData: null,
  userData: null,
  isLoggedIn: false,
  language: i18n.language.substring(0, 2),
};

export type AuthState = typeof initStateAuth;

const AuthReducer = (action: any, state: AuthState = initStateAuth) => {
  switch (action?.type) {
    case actions.SET_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case actions.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case actions.UPDATE_USER_ACCOUNT:
      return {
        ...state,
        userData: action.payload,
      };
    case actions.SWITCH_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};
export default AuthReducer;
